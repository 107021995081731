import React from 'react';
import styles from './ChannelScreenToggle.module.scss';

interface MediaToggleProps {
    activeView: 'channels' | 'screens';
    onToggle: (view: 'channels' | 'screens') => void;
    selectedCount?: {
        channels: number;
        screens: number;
    };
}

export const ChannelScreenToggle: React.FC<MediaToggleProps> = ({
                                                            activeView,
                                                            onToggle,
                                                            selectedCount
                                                        }) => {
    return (
        <div className={styles.toggleContainer}>
            <div className={styles.toggleButtons}>
                <button
                    className={`${styles.toggleButton} ${activeView === 'channels' ? styles.active : ''}`}
                    onClick={() => onToggle('channels')}
                >
                    {'Channels'}
                </button>
                <button
                    className={`${styles.toggleButton} ${activeView === 'screens' ? styles.active : ''}`}
                    onClick={() => onToggle('screens')}
                >
                    {'Screens'}
                </button>
            </div>
        </div>
    );
};
