import {createSlice} from '@reduxjs/toolkit';
import {requestStatusEnum} from '../store.types';
import {
  TActionCreateClusterPayload,
  TActionGetClusterListPayload,
  TActionGetClusterListSuccess,
  TActionGetScreensListPayload,
  TActionGetScreensListSuccess,
  TClusterReducer
} from './cluster.types';


const initialState: TClusterReducer = {
    clusterList: {
        data: [],   
           totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
  selectedState: '',
  selectedCity: '',
    currentPage: 0,
    screensList: {
      data: [],
      totalPages: 0,
      status: requestStatusEnum.INITIAL
    },
  addScreenStatus: requestStatusEnum.INITIAL,
    selectedScreens:[],
    selectedClusters: [],
    createClusterStatus: requestStatusEnum.INITIAL,
  createChannel: {
      createChannelStatus: requestStatusEnum.INITIAL
  },
  userChannel: {
      userChannelStatus: requestStatusEnum.INITIAL,
    data: []
  },
};

const clustersReducer = createSlice({
  name: 'clusters',
  initialState,
  reducers: {
    actionFetchClusters(state, { payload }: TActionGetClusterListPayload) {
      state.clusterList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchClustersSuccess(state, {payload}: TActionGetClusterListSuccess) {
      state.clusterList.status = requestStatusEnum.SUCCESS;
      state.clusterList.data = payload.data;
      state.clusterList.totalPages = payload.totalPages
    },
    actionFetchClustersFailed(state) {
      state.clusterList.status = requestStatusEnum.FAILED;
    },
    actionFetchScreensList(state, {payload}: TActionGetScreensListPayload) {
      state.screensList.status = requestStatusEnum.PROGRESS;
    },
    actionFetchScreensListSuccess(state, {payload}: TActionGetScreensListSuccess) {
      state.screensList.status = requestStatusEnum.SUCCESS;
      state.screensList.data = payload.data
      state.screensList.totalPages = payload.totalPages
    },
    actionFetchScreensListFailed(state) {
      state.screensList.status = requestStatusEnum.FAILED;
      state.screensList.data = []
    },
    actionSetSelectedScreen(state, {payload}) {
      state.selectedScreens = payload;
    },
    actionSetSelectedCluster(state, { payload }) {
      state.selectedClusters = payload;
    },
    actionCreateCluster(state, {payload}: TActionCreateClusterPayload){
      state.createClusterStatus = requestStatusEnum.PROGRESS
    },
    actionCreateClusterSuccess(state, {payload}){
      state.createClusterStatus = requestStatusEnum.SUCCESS
    },
    actionCreateClusterFailed(state){
      state.createClusterStatus = requestStatusEnum.FAILED
    },
    actionResetSelectedCluster(state){
      state.selectedClusters = [];
    },
    actionSetSelectedCity(state, { payload }) {
      state.selectedCity = payload;
    },
    actionSetSelectedState(state, { payload }) {
      state.selectedState = payload;
    },
    actionAddScreen(state, { payload }) {
      state.addScreenStatus = requestStatusEnum.PROGRESS
    },
    actionAddScreenSuccess(state) {
      state.addScreenStatus = requestStatusEnum.SUCCESS
    },
    actionAddScreenFailed(state) {
      state.addScreenStatus = requestStatusEnum.FAILED
    },
    actionCreateChannel(state, {payload}){
      state.createChannel.createChannelStatus = requestStatusEnum.PROGRESS
    },
    actionCreateChannelSuccess(state){
      state.createChannel.createChannelStatus = requestStatusEnum.SUCCESS
    },
    actionCreateChannelFailed(state){
      state.createChannel.createChannelStatus = requestStatusEnum.FAILED
    },
    actionResetChannel(state){
      state.createChannel.createChannelStatus = requestStatusEnum.INITIAL
    },
    actionGetChannelsByUser(state, {payload}){
      state.userChannel.userChannelStatus = requestStatusEnum.PROGRESS
    },
    actionGetChannelsByUserSuccess(state, { payload }){
      state.userChannel.userChannelStatus = requestStatusEnum.SUCCESS;
      state.userChannel.data = payload;
    },
    actionGetChannelsByUserFailed(state){
      state.userChannel.userChannelStatus = requestStatusEnum.FAILED
    },
    actionResetChannelCreateStatus(state) {
      state.createChannel.createChannelStatus = requestStatusEnum.INITIAL;
      state.userChannel.userChannelStatus = requestStatusEnum.INITIAL;
    },
    resetClusters() {
      return initialState;
    },
  },
})

export const {
  actionFetchClusters,
  actionFetchClustersSuccess,
  actionFetchClustersFailed,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionSetSelectedScreen,
    resetClusters,
    actionCreateCluster,
    actionCreateClusterSuccess,
    actionCreateClusterFailed,
    actionSetSelectedCluster,
  actionResetSelectedCluster,
  actionSetSelectedCity,
  actionSetSelectedState,
  actionCreateChannel,
  actionCreateChannelSuccess,
  actionCreateChannelFailed,
  actionResetChannel,
  actionGetChannelsByUser,
  actionGetChannelsByUserSuccess,
  actionGetChannelsByUserFailed,
  actionResetChannelCreateStatus,
  actionAddScreen,
  actionAddScreenSuccess,
  actionAddScreenFailed
} = clustersReducer.actions;

export default clustersReducer.reducer

