
import { auth } from "../../config/firebase";
import { api } from "..";

interface ISubmitEmailProps {
  email: string;
  password: string;
}

export const AuthService = {
  signOut: () => auth.signOut(),

  submitEmailLogin: (payload: ISubmitEmailProps) =>
    auth.signInWithEmailAndPassword(payload.email, payload.password),

  loginUpdate: (payload: { userId: string; type: string }) =>
    api.post('/public/user/login', payload).then((data) => data?.data?.response),

  getUserInfo: (payload: { userId: string }) =>
      api.get(`/public/user/id/${payload.userId}`).then((data) => data?.data?.response),

  updateUser: (payload: any) =>
      api.post(`/public/user/update`, payload ).then((data) => data?.data?.response),
  
  submitEmailSignUp: (payload: ISubmitEmailProps) =>
    auth.createUserWithEmailAndPassword(payload.email, payload.password),

  //   submitGoogleSignIn: () => auth.signInWithPopup(googleAuthProvider),

  confirmResetPassword: (code: string, password: string) =>
    auth.confirmPasswordReset(code, password),

  //   verifyUserEmail: () => sendEmailVerification(auth.currentUser),

  //   updateUserPassword: (password: string) =>
  //     updatePassword(auth.currentUser, password),

  //   updateShopifyUserPassword: (password: string) => {
  //     const auth = getAuth();
  //     return updatePassword(auth.currentUser, password).then(
  //       (response: any) => response
  //     );
  //   },

  //   shopifyUserSignIn: (token: string, email: string, isNewUser: any) => {
  //     const auth = getAuth();
  //     return signInWithCustomToken(auth, token).then((response: any) => {
  //       if (isNewUser) {
  //         sendPasswordResetEmail(auth, email);
  //       }
  //       return response;
  //     });
  //   },
};
