import React, { useState } from 'react';
import { SelectMedia } from '../SelectMedia/SelectMedia.component';
import { MediaToggle } from '../MediaToggle/MediaToggle.component';
import styles from './ScreenChannelSetupView.module.scss';
import {ChannelScreenToggle} from "../ChannelScreenToggle/ChannelScreenToggle.component";
import {SelectScreen} from "../SelectScreen/SelectScreenCluster.component";
import SelectChannel from "../SelectChannel/SelectChannel.component";

interface ScreenChannelSetupViewProps {
    activeView: 'channels' | 'screens';
    onToggleChange: (view: 'channels' | 'screens') => void;
}

export const ScreenChannelSetupView: React.FC<ScreenChannelSetupViewProps> = ({ activeView, onToggleChange }) => {
    const [selectedCount, setSelectedCount] = useState({
        channels: 2,
        screens: 1
    });

    const handleToggle = (view: 'channels' | 'screens') => {
        onToggleChange(view);
    };

    return (
        <div className={styles.setupContainer}>
            <ChannelScreenToggle
                activeView={activeView}
                onToggle={handleToggle}
                selectedCount={selectedCount}
            />
            {activeView === 'screens' ? (
                <SelectScreen />
            ) : (
                <SelectChannel />
            )}
        </div>
    );
};