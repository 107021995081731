import React from 'react';
import styles from './MediaToggle.module.scss';

interface MediaToggleProps {
    activeView: 'playlist' | 'media';
    onToggle: (view: 'playlist' | 'media') => void;
    selectedCount?: {
        playlists: number;
        media: number;
    };
}

export const MediaToggle: React.FC<MediaToggleProps> = ({
                                                            activeView,
                                                            onToggle,
                                                            selectedCount
                                                        }) => {
    return (
        <div className={styles.toggleContainer}>
            <div className={styles.toggleButtons}>
                <button
                    className={`${styles.toggleButton} ${activeView === 'playlist' ? styles.active : ''}`}
                    onClick={() => onToggle('playlist')}
                >
                    Playlist
                </button>
                <button
                    className={`${styles.toggleButton} ${activeView === 'media' ? styles.active : ''}`}
                    onClick={() => onToggle('media')}
                >
                    Media
                </button>
            </div>
        </div>
    );
};