import React, {useEffect, useState} from "react";
import styles from './CreatePlaylist.module.scss';
import {builderNameSectionMode} from "../../../features/BuilderNameSection/BuilderNameSection.types";
import {CreateHeader} from "../../../features/CreateHeader/CreateHeader.component";
import {routes} from "../../../routing/routes";
import {useNavigate} from "react-router";
import Button from "../../../components/Button/Button";
import {ButtonVariantEnum} from "../../../components/Button/button.types";
import {Tooltip} from "../../../components/Tooltip/Tooltip.component";
import {SelectContent} from "./SelectContent/SelectContent.component";
import {useMediaActions} from "../../../store/media/useMediaActions";
import {useMediaSelector} from "../../../store/media/useMediaSelector";
import {useLoginSelector} from "../../../store/login/useLoginSelectors";
import {requestStatusEnum} from "../../../store/store.types";
import {Spinner} from "../../../components/Spinner/Spinner.component";
import {useParams} from "react-router-dom";

interface SelectedMediaItem {
    id: string;
    length: number;
    name?: string;
    size?: number;
    thumbnailUrl?: string;
}

export const CreatePlaylist: React.FC = () => {
    const { id: playlistId } = useParams<{ id: string }>();
    const [inputValue, setInputValue] = useState("");
    const [selectedItems, setSelectedItems] = useState<SelectedMediaItem[]>([]);
    const navigate = useNavigate();
    const {userData} = useLoginSelector()

    const { actionCreatePlaylist, actionUpdatePlaylist, actionGetPlaylistInfo, actionResetPlaylist } = useMediaActions();
    const { playlistCreate, updatePlaylistStatus, playlistInfo } = useMediaSelector();

    const isEditMode = Boolean(playlistId);

    useEffect(() => {
        if (isEditMode && userData?.userId) {
            actionGetPlaylistInfo({
                id: playlistId,
                userId: userData.userId,
            });
        } else {
            actionResetPlaylist();
        }
    }, [isEditMode, playlistId, userData?.userId]);

    useEffect(() => {
        if (isEditMode && playlistInfo?.getPlaylistInfoStatus === requestStatusEnum.SUCCESS) {
            setInputValue(playlistInfo.data.name || '');
            setSelectedItems(playlistInfo.data.mediaDetails?.map((media: any) => ({
                id: media.mediaId,
                length: parseInt(media.len),
                name: media.name,
                size: media.size,
                thumbnailUrl: media.thumbnail
            })) || []);
        }
    }, [isEditMode, playlistInfo]);

    useEffect(() => {
        actionResetPlaylist();
    }, []);

    useEffect(() => {
        const isSuccess = playlistCreate?.playlistCreateStatus === requestStatusEnum.SUCCESS;
        if (isSuccess) {
            navigate(routes().playlist);
        }
    }, [playlistCreate?.playlistCreateStatus]);

    useEffect(() => {
        if (updatePlaylistStatus === requestStatusEnum.SUCCESS) {
            navigate(routes().playlist);
        }
    }, [updatePlaylistStatus]);

    const onInputChange = (value: string) => {
        setInputValue(value);
    };

    const onCloseClick = () => {
        navigate(routes().playlist);
    };

    const handleSaveContent = (selectedItems: SelectedMediaItem[]) => {
        const payload = {
            userId: userData?.userId,
            name: inputValue,
            mediaList: selectedItems.map(item => ({
                mediaId: item.id,
                name: item.name || '',
                len: item.length.toString(),
                size: null
            }))
        };

        if (isEditMode && playlistId) {
            actionUpdatePlaylist({
                ...payload,
                id: playlistId
            });
        } else {
            actionCreatePlaylist(payload);
        }
    };

    const initialMediaItems = isEditMode && playlistInfo?.data?.mediaDetails
        ? playlistInfo.data.mediaDetails.map((media: any) => ({
            id: media.mediaId,
            length: parseInt(media.mediaLen),
            name: media.name,
            size: media.length,
            thumbnailUrl: media.thumbnail
        }))
        : [];


    const isLoading = playlistCreate?.playlistCreateStatus === requestStatusEnum.PROGRESS ||
        (isEditMode && playlistInfo?.getPlaylistInfoStatus === requestStatusEnum.PROGRESS) || (updatePlaylistStatus === requestStatusEnum.PROGRESS);

    console.log('initial media items => ', initialMediaItems);

    return (
        <div>
            <CreateHeader
                headerText={isEditMode ? "Edit Playlist" : "Create New Playlist"}
                name={inputValue}
                setName={onInputChange}
                nameFieldPlaceholder={"Enter Playlist Name"}
                onCloseClick={onCloseClick}
                mode={isEditMode ? builderNameSectionMode.EDIT : builderNameSectionMode.CREATE}
            />
            {isLoading ? (
                <Spinner/>
                ) : (
                <div className={styles.createPlaylistWrapper}>
                    <SelectContent
                        onSave={handleSaveContent}
                        isDisabled={!inputValue}
                        initialItems={initialMediaItems}
                    />
                </div>
            )}
        </div>
    );
};