import React, { useState, useRef, useEffect } from 'react';
import { columnConfigType } from "../../components/Table/Table.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import styles from './Playlist.module.scss';
import {addMinutes, format} from "date-fns";

export type TMediaDetail = {
    mediaId: string;
    mediaLen: number;
    mediaSize: number | null;
    thumbnail: string;
};

export type TPlaylistData = {
    id: string;
    name: string;
    totalLength: number;
    totalSize: number | null;
    mediaDetails: TMediaDetail[];
    status: string;
    createdAt: string;
    updatedAt: string;
    published: boolean;
};

interface ActionMenuProps {
    data: TPlaylistData;
    onEdit: (playlistId: string) => void;
    onCopy: (playlistId: string) => void;
    onDelete: (playlistId: string) => void;
}

const ActionMenu: React.FC<ActionMenuProps> = ({ data, onEdit, onCopy, onDelete }) => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleEdit = () => {
        onEdit(data.id);
        setIsOpen(false);
    };

    const handleCopy = () => {
        onCopy(data.id);
        setIsOpen(false);
    };

    const handleDelete = () => {
        onDelete(data.id);
        setIsOpen(false);
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.actionMenuWrapper} ref={menuRef}>
            <button
                onClick={toggleMenu}
                className={styles.dotsButton}
            >
                <img
                    src="./icons/three_dots_icon.svg"
                    alt="More options"
                    width={16}
                    height={16}
                />
            </button>
            {isOpen && (
                <div className={styles.optionsContainer}>
                    <button
                        onClick={handleEdit}
                        className={styles.actionButton}
                    >
                        <img src="./icons/edit.svg" alt="Edit" />
                        <span>Edit</span>
                    </button>
                    <button
                        onClick={handleCopy}
                        className={styles.actionButton}
                    >
                        <img src="./icons/copy.svg" alt="Copy" />
                        <span>Copy</span>
                    </button>
                    <button
                        onClick={handleDelete}
                        className={styles.actionButton}
                    >
                        <img src="./icons/delete.svg" alt="Delete" />
                        <span>Delete</span>
                    </button>
                </div>
            )}
        </div>
    );
};

export const PlaylistTableColumns = (
    onEdit: (playlistId: string) => void,
    onCopy: (playlistId: string) => void,
    onDelete: (playlistId: string) => void
): columnConfigType<TPlaylistData>[] => [
    {
        id: 'name',
        name: 'Name',
        cellRenderer: (data) => (
            <div>
                <Text
                    variant={TextVariantsEnum.Text_md}
                    fontWeight={FontWeightEnum.Normal}
                    color={'neutral-70'}
                >
                    {data.name}
                </Text>
            </div>
        )
    },
    {
        id: 'mediaDetails',
        name: 'Media',
        cellRenderer: (data) => (
            <div className={styles.mediaThumbnails}>
                {data.mediaDetails.slice(0, 3).map((media, index) => (
                    <div key={media.mediaId} className={styles.thumbnailWrapper}>
                        <img
                            src={`data:image/jpeg;base64,${media.thumbnail}`}
                            alt={`Media ${index + 1}`}
                            className={styles.thumbnail}
                        />
                    </div>
                ))}
                {data.mediaDetails.length > 3 && (
                    <div className={styles.thumbnailWrapper}>
                        <div className={styles.thumbnailOverlay}>
                            <Text
                                variant={TextVariantsEnum.Text_sm}
                                fontWeight={FontWeightEnum.Medium}
                                className={styles.overlayText}
                            >
                                +{data.mediaDetails.length - 3}
                            </Text>
                        </div>
                        <img
                            src={`data:image/jpeg;base64,${data.mediaDetails[3]?.thumbnail}`}
                            alt={`Media ${4}`}
                            className={styles.thumbnail}
                        />
                    </div>
                )}
            </div>
        )
    },
    {
        id: 'createdAt',
        name: 'Created at',
        cellRenderer: (data) => {
            const utcDate = new Date(data.createdAt);
            const istDate = addMinutes(utcDate, 330);

            return (
                <div>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        fontWeight={FontWeightEnum.Normal}
                    >
                        {format(istDate, 'MMM dd, yyyy; hh:mm a')}
                    </Text>
                </div>
            );
        }
    },
    {
        id: 'actions',
        name: '',
        cellRenderer: (data) => (
            <ActionMenu
                data={data}
                onEdit={onEdit}
                onCopy={onCopy}
                onDelete={onDelete}
            />
        )
    }
];