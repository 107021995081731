import React, { useState } from 'react';
import { SelectMedia } from '../SelectMedia/SelectMedia.component';
import { MediaToggle } from '../MediaToggle/MediaToggle.component';
import styles from './SetupView.module.scss';
import {SelectPlaylist} from "../SelectPlaylist/SelectPlaylist.component";

interface SetupViewProps {
    activeView: 'playlist' | 'media';
    onToggleChange: (view: 'playlist' | 'media') => void;
}

export const SetupView: React.FC<SetupViewProps> = ({ activeView, onToggleChange }) => {
    const [selectedCount, setSelectedCount] = useState({
        playlists: 2,
        media: 1
    });

    const handleToggle = (view: 'playlist' | 'media') => {
        onToggleChange(view);
    };

    return (
        <div className={styles.setupContainer}>
            <MediaToggle
                activeView={activeView}
                onToggle={handleToggle}
                selectedCount={selectedCount}
            />
            {activeView === 'media' ? (
                <SelectMedia />
            ) : (
                <div className={styles.playlistPlaceholder}>
                    <SelectPlaylist />
                </div>
            )}
        </div>
    );
};