import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actionFetchClusters, resetClusters,
  actionFetchScreensList,
  actionSetSelectedScreen,
  actionSetSelectedCluster,
  actionCreateCluster,
    actionResetSelectedCluster,
  actionSetSelectedCity,
  actionSetSelectedState,
    actionCreateChannel,
    actionResetChannel,
    actionGetChannelsByUser,
  actionResetChannelCreateStatus,
    actionAddScreen
 } from './cluster.reducer';

export const useClustersActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      actionFetchClusters,
      resetClusters,
        actionFetchScreensList,
        actionSetSelectedScreen,
        actionCreateCluster,
        actionSetSelectedCluster,
        actionResetSelectedCluster,
      actionSetSelectedCity,
      actionSetSelectedState,
        actionCreateChannel,
        actionResetChannel,
        actionGetChannelsByUser,
        actionAddScreen,
      actionResetChannelCreateStatus
    },
    dispatch
  );
};