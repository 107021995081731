import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actionSubmitLogin,
  actionSubmitLoginSuccess,
  actionSubmitLoginFailed,
  actionSetUserData,
  actionSetActiveNav,
  actionLogout,
    actionGetUserInfoById,
    actionGetUserInfoByIdSuccess,
    actionGetUserInfoByIdFailed,
    actionUpdateUser,
    actionUpdateUserSuccess,
    actionUpdateUserFailed
} from './login.reducer';

export const useLoginActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      actionSubmitLogin,
      actionSubmitLoginSuccess,
      actionSubmitLoginFailed,
      actionSetUserData,
      actionSetActiveNav,
      actionLogout,
        actionGetUserInfoById,
        actionGetUserInfoByIdSuccess,
        actionGetUserInfoByIdFailed,
        actionUpdateUser,
        actionUpdateUserSuccess,
        actionUpdateUserFailed
    },
    dispatch
  );
};