import { format, formatDistanceToNow } from 'date-fns';


export const dateFormatter = (date: string | Date | number) => {
  try {
    if (!date) return '-';
    const currentDate = new Date(Number(date));
    return `${format(currentDate, 'dd MMM yyyy')}`;
  } catch (err) {
    return '-';
  }
};

export const formatEpochToISTDateTime = (epochTimestamp: number): string => {
  if (!epochTimestamp) return '-';

  const timestampInMs = epochTimestamp < 10000000000
      ? epochTimestamp * 1000
      : epochTimestamp;

  const date = new Date(timestampInMs);

  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
    timeZone: 'Asia/Kolkata'
  }).replace(',', '');
};

export const relativeTimeObjectFormatter = (dateObject: {
  seconds: number;
  nanos: number;
}) => {
  if (!dateObject || !dateObject.seconds) return '-';

  const { seconds, nanos } = dateObject;
  const dateInMillis = seconds * 1000 + nanos / 1000000;

  const targetDate = new Date(dateInMillis);

  return formatDistanceToNow(targetDate, { addSuffix: true });
};