import {createSlice} from '@reduxjs/toolkit';
import {requestStatusEnum} from '../store.types';
import {
    TActionGetCampaignsListPayload,
    TActionGetCampaignsListSuccess,
    TActionGetMediaLibraryPayload,
    TActionGetMediaLibrarySuccess,
    TActionGetMerchantScreenCampaignsListPayload,
    TActionGetMerchantScreensCampaignsListSuccess,
    TActionGetScreensListPayload,
    TActionGetScreensListSuccess, TActionGetSelectableScreensListPayload, TActionGetUserScreensListSuccess,
    TActionSetScreenDefaultMediaPayload,
    TCampaignsReducer
} from './campaigns.types';

const initialState: TCampaignsReducer = {
    campaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    merchantScreenCampaignsList: {
        data: [],
        status: requestStatusEnum.INITIAL
    },
    createCampaignPayload: {
        name: '',
        userId: '',
        orgId: '',
        clusters: [],
        slots: [],
        mediaId: '',
        mediaLengthInSec: '',
        startTime: '',
        endTime: '',
    },
    cancelCampaignStatus: requestStatusEnum.INITIAL,
    setDefaultMediaStatus: requestStatusEnum.INITIAL,
    createMerchantScreenCampaignPayload: {
        name: '',
        userId: '',
        orgId: '',
        mediaId: [],
        mediaLengthInSec: '',
        screens:[],
        startTime: '',
        endTime: '',
        slots: [],
    },
    selectedPlaylist: {
        data: [],
        playlistLength: 0,
        isPlaylist: false,
    },
    createMerchantChannelCampaignPayload: {
        name: '',
        userId: '',
        orgId: '',
        playlistId: [],
        playlistLengthInSec: 0,
        mediaId: [],
        mediaLengthInSec: '',
        screens:[],
        startTime: '',
        endTime: '',
        slots: [],
    },
    campaignMerchantScreenScheduleStatus: requestStatusEnum.INITIAL,
    campaignScheduleStatus: requestStatusEnum.INITIAL,
    campaignSaveAsDraftStatus: requestStatusEnum.INITIAL,
    currentPage: 0,
    mediaLibrary: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    SelectedCampaignType: localStorage.getItem('SelectedCampaignType') || '',
    selectedMedia: [],
    selectedMediaLength: 0,
    screensList: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    userScreensList: {
        data: [],
        totalPages: 0,
        status: requestStatusEnum.INITIAL
    },
    campaignInfo: {
        status: requestStatusEnum.INITIAL,
        data: null,
    },
    selectedScreens: [],
    selectedDates: [],
    selectedSlots: [],
    selectedClusterSlots: [],
    selectedScreenSlots: [],
    selectedChannels: [],
};

const campaignsReducer = createSlice({
    name: 'campaigns',
    initialState,
    reducers: {
        actionSetSelectedChannels(state, { payload }) {
            state.selectedChannels = payload
        },
        actionFetchCampaigns(state, { payload }: TActionGetCampaignsListPayload) {
            state.campaignsList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchCampaignsSuccess(state, { payload }: TActionGetCampaignsListSuccess) {
            state.campaignsList.status = requestStatusEnum.SUCCESS;
            state.campaignsList.data = payload.data;
        },
        actionSetSelectedPlaylist(state, { payload }){
            state.selectedPlaylist.playlistLength = payload.playlistLength;
            state.selectedPlaylist.data = payload.data;
            state.selectedPlaylist.isPlaylist = payload.isPlaylist;
        },
        actionFetchCampaignsFailed(state) {
            state.campaignsList.status = requestStatusEnum.FAILED;
        },
        actionFetchMerchantScreenCampaigns(state, { payload }: TActionGetMerchantScreenCampaignsListPayload) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchMerchantScreenCampaignsSuccess(state, { payload }: TActionGetMerchantScreensCampaignsListSuccess) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.SUCCESS;
            state.merchantScreenCampaignsList.data= payload;
        },
        actionFetchMerchantScreenCampaignsFailed(state) {
            state.merchantScreenCampaignsList.status = requestStatusEnum.FAILED;
        },
        actionFetchMediaLibrary(state, { payload }: TActionGetMediaLibraryPayload) {
            state.mediaLibrary.status = requestStatusEnum.PROGRESS;
        },
        actionFetchMediaLibrarySuccess(state, { payload }: TActionGetMediaLibrarySuccess) {
            state.mediaLibrary.status = requestStatusEnum.SUCCESS;
            state.mediaLibrary.data = payload.data;
            state.mediaLibrary.totalPages = payload.totalPages;
        },
        actionFetchMediaLibraryFailed(state) {
            state.mediaLibrary.status = requestStatusEnum.FAILED;
            state.mediaLibrary.data = [];
        },
        actionSetSelectedMedia(state, { payload }) {
            state.selectedMedia = payload?.selectedMedia;
            state.selectedMediaLength = payload?.selectedMediaLength
        },
        actionFetchScreensList(state, { payload }: TActionGetScreensListPayload) {
            state.userScreensList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchScreensListSuccess(state, { payload }: TActionGetUserScreensListSuccess) {
            state.userScreensList.status = requestStatusEnum.SUCCESS;
            state.userScreensList.data = payload.data;
            state.userScreensList.totalPages = payload.totalPages;
        },
        actionFetchScreensListFailed(state) {
            state.userScreensList.status = requestStatusEnum.FAILED;
        },
        actionFetchSelectableScreensList(state, { payload }: TActionGetSelectableScreensListPayload) {
            state.screensList.status = requestStatusEnum.PROGRESS;
        },
        actionFetchSelectableScreensListSuccess(state, { payload }: TActionGetScreensListSuccess) {
            state.screensList.status = requestStatusEnum.SUCCESS;
            state.screensList.data = payload.data;
            state.screensList.totalPages = payload.totalPages;
        },
        actionFetchSelectableScreensListFailed(state) {
            state.screensList.status = requestStatusEnum.FAILED;
            state.screensList.data = [];
        },
        actionSetScreenDefaultMedia(state, { payload }: TActionSetScreenDefaultMediaPayload) {
            state.setDefaultMediaStatus = requestStatusEnum.PROGRESS
        },
        actionSetScreenDefaultMediaSucess(state) {
            state.setDefaultMediaStatus = requestStatusEnum.SUCCESS
        },
        actionSetScreenDefaultMediaFailed(state) {
            state.setDefaultMediaStatus = requestStatusEnum.FAILED
        },
        actionSetSelectedScreen(state, { payload }) {
            state.selectedScreens = payload;
        },
        actionSetSelectedDates(state, { payload }) {
            state.selectedDates = payload;
        },
        actionSetSelectedSlots(state, { payload }) {
            state.selectedSlots = payload;
        },
        actionSetSelectedCampaignType(state, { payload }) {
            state.SelectedCampaignType = payload;
            localStorage.setItem('SelectedCampaignType', payload);
        },
        actionAddClusterSlots(state, { payload }){
            state.selectedClusterSlots = payload;
        },
        actionAddScreensSlots(state, { payload }){
            state.selectedScreenSlots = payload;
        },
        actionUpdateCreateCampaignPayload(state, { payload }) {
            state.createCampaignPayload = {
                ...state.createCampaignPayload,
                ...payload,
            };
        },
        actionUpdateCreateMerchantScreenCampaignPayload(state, { payload }) {
            state.createMerchantScreenCampaignPayload = {
                ...state.createMerchantScreenCampaignPayload,
                ...payload,
            };
        },
        actionUpdateCreateMerchantChannelsCampaignPayload(state, { payload }) {
            state.createMerchantChannelCampaignPayload = {
                ...state.createMerchantChannelCampaignPayload,
                ...payload,
            };
        },
        actionScheduleCampaign(state, {payload}){
            state.campaignScheduleStatus = requestStatusEnum.PROGRESS;
        },
        actionScheduleCampaignSuccess(state, {payload}) {
            state.campaignScheduleStatus = requestStatusEnum.SUCCESS;
        },
        actionScheduleCampaignFailed(state) {
            state.campaignScheduleStatus = requestStatusEnum.FAILED;
        },
        actionScheduleMerchantScreensCampaign(state, {payload}){
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.PROGRESS;
        },
        actionScheduleMerchantScreensCampaignSuccess(state, {payload}) {
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.SUCCESS;
        },
        actionScheduleMerchantScreensCampaignFailed(state) {
            state.campaignMerchantScreenScheduleStatus = requestStatusEnum.FAILED;
        },
        actionCampaignSaveAsDraft(state, {payload}){
            state.campaignSaveAsDraftStatus = requestStatusEnum.PROGRESS;
        },
        actionCampaignSaveAsDraftSuccess(state){
            state.campaignSaveAsDraftStatus = requestStatusEnum.SUCCESS;
        },
        actionCampaignSaveAsDraftFailed(state){
            state.campaignSaveAsDraftStatus = requestStatusEnum.FAILED;
        },
        actionCancelCampaign(state, {payload}) {
            state.cancelCampaignStatus = requestStatusEnum.PROGRESS;
        },
        actionCancelCampaignSuccess(state) {
            state.cancelCampaignStatus = requestStatusEnum.SUCCESS;
        },
        actionCancelCampaignFailed(state) {
            state.cancelCampaignStatus = requestStatusEnum.FAILED;
        },
        actionGetCampaignInfo(state, { payload }){
            state.campaignInfo.status = requestStatusEnum.PROGRESS;
        },
        actionGetCampaignInfoSuccess(state, { payload }){
            state.campaignInfo.status = requestStatusEnum.SUCCESS;
        },
        actionGetCampaignInfoFailed(state, { payload }){
            state.campaignInfo.status = requestStatusEnum.FAILED;
        },
        resetCampaigns() {
            localStorage.removeItem('SelectedCampaignType');
            return initialState;
        },
    },
});

export const {
    actionFetchCampaigns,
    actionFetchCampaignsSuccess,
    actionFetchCampaignsFailed,
    actionFetchMediaLibrary,
    actionFetchMediaLibrarySuccess,
    actionFetchMediaLibraryFailed,
    actionSetSelectedMedia,
    actionFetchSelectableScreensList,
    actionFetchSelectableScreensListSuccess,
    actionFetchSelectableScreensListFailed,
    actionSetSelectedScreen,
    actionSetSelectedDates,
    actionSetSelectedSlots,
    actionSetSelectedCampaignType,
    actionAddClusterSlots,
    actionUpdateCreateCampaignPayload,
    actionScheduleCampaign,
    actionScheduleCampaignSuccess,
    actionScheduleCampaignFailed,
    actionScheduleMerchantScreensCampaign,
    actionScheduleMerchantScreensCampaignSuccess,
    actionScheduleMerchantScreensCampaignFailed,
    actionCampaignSaveAsDraft,
    actionCampaignSaveAsDraftSuccess,
    actionCampaignSaveAsDraftFailed,
    actionUpdateCreateMerchantScreenCampaignPayload,
    actionFetchMerchantScreenCampaigns,
    actionFetchMerchantScreenCampaignsSuccess,
    actionFetchMerchantScreenCampaignsFailed,
    actionSetScreenDefaultMedia,
    actionSetScreenDefaultMediaSucess,
    actionSetScreenDefaultMediaFailed,
    actionFetchScreensList,
    actionFetchScreensListSuccess,
    actionFetchScreensListFailed,
    actionCancelCampaign,
    actionCancelCampaignSuccess,
    actionCancelCampaignFailed,
    actionGetCampaignInfo,
    actionGetCampaignInfoSuccess,
    actionGetCampaignInfoFailed,
    actionAddScreensSlots,
    actionSetSelectedChannels,
    actionUpdateCreateMerchantChannelsCampaignPayload,
    actionSetSelectedPlaylist,
    resetCampaigns
} = campaignsReducer.actions;

export default campaignsReducer.reducer;
