import { MediaCard } from "../../../../components/MediaCard/MediaCard.component";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../../../store/store.types";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useEffect, useState } from "react";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";
import styles from "./SelectMultipleMedia.module.scss";

interface SelectedMediaItem {
    id: string;
    length: number;
    name?: string;
    size?: number;
}

interface SelectMultipleMediaProps {
    onSelectionChange: (selectedItems: SelectedMediaItem[]) => void;
}

export const SelectMultipleMedia: React.FC<SelectMultipleMediaProps> = ({ onSelectionChange }) => {
    const [startRecord, setStartRecord] = useState(0);
    const [selectedMediaItems, setSelectedMediaItems] = useState<SelectedMediaItem[]>([]);

    const { actionFetchMediaLibrary } = useCampaignsActions();
    const {
        mediaLibrary: { data: mediaLibraryData, status: mediaLibraryStatus },
    } = useCampaignsSelector();

    const { userData: { ordId: orgId } } = useLoginSelector();

    useEffect(() => {
        if(orgId) {
            actionFetchMediaLibrary({ page: startRecord, orgId, status: 'ALL'});
        }
    }, [orgId, startRecord]);

    const handleSelectMedia = (id: string, length: number, name: string | undefined, size: number | undefined, thumbnailUrl: string) => {
        setSelectedMediaItems(prevItems => {
            const isAlreadySelected = prevItems.some(item => item.id === id);
            const newItems = isAlreadySelected
                ? prevItems.filter(item => item.id !== id)
                : [...prevItems, { id, length, name, thumbnailUrl }];

            onSelectionChange(newItems);
            return newItems;
        });
    };

    const isMediaSelected = (id: string): boolean => {
        return selectedMediaItems.some(item => item.id === id);
    };

    return (
        <>
            {mediaLibraryStatus === requestStatusEnum.PROGRESS ? (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.mediaWrapper}>
                    {mediaLibraryData.length > 0 ? (
                        mediaLibraryData.map((mediaData, index) => (
                            <MediaCard
                                key={`${mediaData.id}-${index}`}
                                mediaId={mediaData.id}
                                mediaSrc={`data:image/jpeg;base64,${mediaData.thumbnail}`}
                                mediaName={mediaData?.name}
                                mediaTags={mediaData.tags}
                                selected={isMediaSelected(mediaData.id)}
                                selectedMediaLengthInSec={mediaData?.length}
                                handleSelectMedia={handleSelectMedia}
                            />
                        ))
                    ) : (
                        <div className={styles.noMediaMessage}>
                            No verified media Found
                        </div>
                    )}
                </div>
            )}
        </>
    );
};