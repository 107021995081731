import { MediaCard } from "../../../../components/MediaCard/MediaCard.component";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import styles from "./SelectMedia.module.scss";
import { requestStatusEnum } from "../../../../store/store.types";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useEffect, useState } from "react";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";

export const SelectMedia: React.FC = () => {
    const [startRecord, setStartRecord] = useState(0);

    const { actionSetSelectedMedia, actionFetchMediaLibrary } = useCampaignsActions();
    const {
        mediaLibrary: { data: mediaLibraryData, status: mediaLibraryStatus },
        selectedMedia,
    } = useCampaignsSelector();

    const { userData: { ordId: orgId, type: userType } } = useLoginSelector();

    useEffect(() => {
        if(orgId)
            actionFetchMediaLibrary({page: startRecord, orgId, status: (userType === 'MERCHANT' || userType === 'MERCHANT') ? 'ALL' : 'VERIFIED' })
    }, [orgId]);

    const handleSelectMedia = (id: string, length: number) => {
        let newSelectedMedia: string[];
        let totalLength = 0;

        const currentSelected = Array.isArray(selectedMedia)
            ? selectedMedia
            : selectedMedia ? [selectedMedia] : [];

        if (currentSelected.includes(id)) {
            newSelectedMedia = currentSelected.filter(mediaId => mediaId !== id);
        } else {
            newSelectedMedia = [...currentSelected, id];
        }

        // Calculate total length of all selected media
        totalLength = mediaLibraryData
            .filter(media => newSelectedMedia.includes(media.id))
            .reduce((sum, media) => sum + (media.length || 0), 0);

        actionSetSelectedMedia({
            selectedMedia: newSelectedMedia,
            selectedMediaLength: totalLength
        });
    };

    return (
        <>
            {mediaLibraryStatus === requestStatusEnum.PROGRESS ? (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.mediaWrapper}>
                    {mediaLibraryData.length > 0 ? (
                        mediaLibraryData.map((mediaData, index) => (
                            <MediaCard
                                key={`${mediaData.id}-${index}`}
                                mediaId={mediaData.id}
                                mediaSrc={`data:image/jpeg;base64,${mediaData.thumbnail}`}
                                mediaName={mediaData?.name}
                                mediaTags={mediaData.tags}
                                selected={Array.isArray(selectedMedia)
                                    ? selectedMedia.includes(mediaData.id)
                                    : selectedMedia === mediaData.id}
                                selectedMediaLengthInSec={mediaData?.length}
                                handleSelectMedia={handleSelectMedia}
                                multiSelect={true}
                            />
                        ))
                    ) : (
                        <div className={styles.noMediaMessage}>
                            No verified media Found
                        </div>
                    )}
                </div>
            )}
        </>
    );
};