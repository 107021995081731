import React, { useState } from 'react';
import Button from '../../../../components/Button/Button';
import { ButtonVariantEnum } from '../../../../components/Button/button.types';
import { Text, TextVariantsEnum } from '../../../../components/Text';
import { FontWeightEnum } from '../../../../components/Text';
import styles from './SelectContent.module.scss';
import { Modal } from "../../../../components/Modal";
import { SelectContentModalComponent } from "../SelectContentModal/SelectContentModal.component";
import {useMediaActions} from "../../../../store/media/useMediaActions";
import {actionCreatePlaylist} from "../../../../store/media/media.reducer";
import {PlaylistContent} from "../PlaylistContent/PlaylistContent.component";

interface SelectedMediaItem {
    id: string;
    length: number;
    name?: string;
    size?: number;
    thumbnailUrl?: string;
}

interface SelectContentProps {
    onSave: (selectedItems: SelectedMediaItem[]) => void;
    isDisabled: boolean;
    initialItems?: SelectedMediaItem[];
}


export const SelectContent: React.FC<SelectContentProps> = ({ onSave, isDisabled, initialItems = []  }) => {
    const [isContentModalVisible, setIsContentModalVisible] = useState<boolean>(false);
    const [selectedContent, setSelectedContent] = useState<SelectedMediaItem[]>(initialItems);
    const handleSelectContentButton = () => {
        setIsContentModalVisible(true);
    };

    console.log('selectedContent', selectedContent)

    const onClose = () => {
        setIsContentModalVisible(false);
    };

    const handleItemsAdd = (items: SelectedMediaItem[]) => {
        setSelectedContent(items);
        setIsContentModalVisible(false);
    };

    const handleSave = () => {
        onSave(selectedContent);
    };

    const handleRemoveItem = (itemId: string) => {
        setSelectedContent(prevContent => prevContent.filter(item => item.id !== itemId));
    };

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.headerContent}>
                    <Text
                        variant={TextVariantsEnum.Display_md}
                        fontWeight={FontWeightEnum.Semibold}
                        color="gray-900"
                    >
                        Content
                    </Text>
                    <Text
                        variant={TextVariantsEnum.Text_md}
                        fontWeight={FontWeightEnum.Normal}
                        color="gray-600"
                    >
                        What content do you want to play? Add content, and drag it up or down to change the content order.
                    </Text>
                </div>
            </div>
            <div className={styles.outerWrapper}>
                <div className={styles.wrapper}>
                    <div className={styles.content}>
                        {selectedContent.length === 0 ? (
                            <>
                                <div className={styles.emptyStateMessage}>
                                    <Text
                                        variant={TextVariantsEnum.Text_md}
                                        fontWeight={FontWeightEnum.Medium}
                                        color="gray-700"
                                    >
                                        You haven't chosen any content yet.
                                    </Text>
                                    <Text
                                        variant={TextVariantsEnum.Text_sm}
                                        fontWeight={FontWeightEnum.Normal}
                                        color="gray-500"
                                    >
                                        Your playlist is hungry... add some content to play!
                                    </Text>
                                </div>
                                <Button
                                    text="Select Content"
                                    variant={ButtonVariantEnum.primary}
                                    size="md"
                                    className={styles.button}
                                    onClick={handleSelectContentButton}
                                />
                            </>
                        ) : (
                            <PlaylistContent
                                items={selectedContent}
                                onReorder={(newItems) => setSelectedContent(newItems)}
                                onRemove={handleRemoveItem}
                            />
                        )}
                        <Button
                            text="Select Content"
                            variant={ButtonVariantEnum.primary}
                            size="md"
                            className={styles.button}
                            onClick={handleSelectContentButton}
                        />
                    </div>
                </div>
                {selectedContent.length > 0 && (
                    <div className={styles.saveButtonContainer}>
                        <Button
                            text="Save"
                            variant={ButtonVariantEnum.primary}
                            size="md"
                            onClick={handleSave}
                            disabled={isDisabled}
                        />
                    </div>
                )}
            </div>
            <Modal
                isVisible={isContentModalVisible}
                onClose={onClose}
                className={styles.modalClass}
            >
                <SelectContentModalComponent onItemsAdd={handleItemsAdd} />
            </Modal>
        </div>
    );
};