import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    actionGetMediaStream,
    actionResetMediaUploadData,
    actionDeletePlaylist,
    actionGetPlaylistInfo, actionGetPlaylist,
    actionResetPlaylist,
    actionSetMediaUploadData,
    actionUploadMedia,
    actionCreatePlaylistFailed,
    actionCreatePlaylist,
    actionCreatePlaylistSuccess,actionClearPlaylistInfo,
    actionUpdatePlaylist
} from './media.reducer';

export const useMediaActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
        actionGetMediaStream,
        actionUploadMedia,
        actionSetMediaUploadData,
        actionResetMediaUploadData,
        actionCreatePlaylist,
        actionCreatePlaylistSuccess,
        actionCreatePlaylistFailed,
        actionResetPlaylist,
        actionGetPlaylist,
        actionDeletePlaylist,
        actionGetPlaylistInfo,
        actionClearPlaylistInfo,
        actionUpdatePlaylist
    },
    dispatch
  );
};