import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { SvgIcon } from "../../components/SvgIcon";
import { Text, TextVariantsEnum } from "../../components/Text";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { useLoginActions } from "../../store/login/useLoginActions";
import { NAV_ITEM_NAME, TNavBarItem, navbarItem } from "./Navbar.utils";
import classNames from "classnames";
import styles from './Navbar.module.scss';
import { routes } from '../../routing/routes';

const ChevronSVG: React.FC<{ className?: string }> = ({ className }) => (
    <svg
        width="15"
        height="15"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M6 9L12 15L18 9"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export const NavbarComponent: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { userData } = useLoginSelector();
    const { actionSetActiveNav, actionLogout } = useLoginActions();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [currentNav, setCurrentNav] = useState<NAV_ITEM_NAME>(NAV_ITEM_NAME.OVERVIEW);

    useEffect(() => {
        const updateNavFromPath = () => {
            const path = location.pathname;
            const matchingNavItem = navbarItem(currentNav).find(item => item.route === path);
            if (matchingNavItem) {
                setCurrentNav(matchingNavItem.name);
                actionSetActiveNav(matchingNavItem.name);
                sessionStorage.setItem('currentNav', matchingNavItem.name);
            }
        };

        updateNavFromPath();
    }, [location, actionSetActiveNav, currentNav]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleActiveNav = (navData: TNavBarItem) => {
        setCurrentNav(navData.name);
        sessionStorage.setItem('currentNav', navData.name);
        actionSetActiveNav(navData.name);
        navigate(navData.route);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleLogout = () => {
        actionLogout();
        setIsDropdownOpen(false);
        navigate(routes().login); 
    };

    return (
        <div className={styles.navbarWrapper}>
            <img src={'./images/logo_white.svg'} width={135} alt="Logo" />
            <div className={styles.headerLinks}>
                {navbarItem(currentNav).map((item, index) => (
                    <div
                        key={index}
                        className={classNames(styles.item, { [styles.active]: item.active })}
                        onClick={() => handleActiveNav(item)}
                    >
                        <SvgIcon
                            className={classNames({ [styles.activeIcon]: item.active })}
                            size={item.active ? 28 : 14}
                            src={item.icon}
                        />
                        <Text
                            variant={TextVariantsEnum.Text_sm}
                            color={item.active ? 'white' : 'neutral-30'}
                        >
                            {item.name}
                        </Text>
                    </div>
                ))}
            </div>
            <div className={styles.userSection} ref={dropdownRef}>
                <div className={styles.userInfo} onClick={toggleDropdown}>
                    <img src="./images/media1.svg" alt="User avatar" className={styles.userAvatar} />
                    <Text variant={TextVariantsEnum.Text_sm} color="white">{userData.name}</Text>
                    <ChevronSVG className={classNames(styles.chevron, { [styles.chevronUp]: isDropdownOpen })} />
                </div>
                {isDropdownOpen && (
                    <div className={styles.dropdown}>
                        <div className={styles.dropdownItem}>
                            <SvgIcon src="./images/account_icon.svg" size={16} />
                            <Text variant={TextVariantsEnum.Text_sm} color="neutral-70" onClick={() => navigate(routes().settings)}>Account</Text>
                        </div>
                        {/*<div className={styles.dropdownItem}>
                            <SvgIcon src="./images/billing_icon.svg" size={16} />
                            <Text variant={TextVariantsEnum.Text_sm} color="neutral-70">Billing</Text>
                        </div>*/}
                        <div className={styles.divider} />
                        <div className={`${styles.dropdownItem} ${styles.logout}`} onClick={handleLogout}>
                            <SvgIcon src="./images/logout_icon.svg" size={16} />
                            <Text variant={TextVariantsEnum.Text_sm} color="error-50">Log Out</Text>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};