import {createSlice} from '@reduxjs/toolkit';
import {requestStatusEnum} from '../store.types';
import {TMediaReducer} from './media.types';


const initialState: TMediaReducer = {
    media: {
      data: null,
      status: requestStatusEnum.INITIAL
    },
    mediaDataUploaded:{
      file: null,
      mediaName: '',
      tags: '',
    },
    mediaUpload:{
      data: null,
      status: requestStatusEnum.INITIAL
    },
  playlistCreate: {
      playlistCreateStatus: requestStatusEnum.INITIAL
  },
  playlistDeleteStatus: requestStatusEnum.INITIAL,
  userPlaylists: {
      getPlaylistStatus: requestStatusEnum.INITIAL,
      data: []
  },
  playlistInfo: {
      getPlaylistInfoStatus: requestStatusEnum.INITIAL,
      data: null,
  },
  updatePlaylistStatus: requestStatusEnum.INITIAL
};

const mediaReducer = createSlice({
  name: 'media',
  initialState,
  reducers: {
    actionGetMediaStream(state, {payload}){
      state.media.status = requestStatusEnum.PROGRESS
    },
    actionGetMediaStreamSuccess(state, {payload}){
      state.media.status = requestStatusEnum.SUCCESS
      state.media.data = payload
    },
    actionGetMediaStreamFailed(state){
      state.media.status = requestStatusEnum.FAILED
      state.media.data = null
    },

    actionSetMediaUploadData(state, {payload}){
      state.mediaDataUploaded = {
        ...state.mediaDataUploaded,
        ...payload
      }
    },
    actionResetMediaUploadData(state){
      state.mediaDataUploaded = initialState.mediaDataUploaded
    },
    actionUploadMedia(state, {payload}){
      state.mediaUpload.status = requestStatusEnum.PROGRESS
    },
    actionUploadMediaSuccess(state, {payload}){
      state.mediaUpload.status = requestStatusEnum.SUCCESS
      state.mediaUpload.data = payload
    },
    actionUploadMediaFailed(state){
      state.mediaUpload.status = requestStatusEnum.FAILED
      state.mediaUpload.data = null
    },
    actionCreatePlaylist(state, {payload}) {
      state.playlistCreate.playlistCreateStatus = requestStatusEnum.PROGRESS;
    },
    actionCreatePlaylistSuccess(state) {
      state.playlistCreate.playlistCreateStatus = requestStatusEnum.SUCCESS;
    },
    actionCreatePlaylistFailed(state) {
      state.playlistCreate.playlistCreateStatus = requestStatusEnum.FAILED;
    },
    actionResetPlaylist(state) {
      state.playlistCreate.playlistCreateStatus = requestStatusEnum.INITIAL;
    },
    actionGetPlaylist(state, {payload}) {
      state.userPlaylists.getPlaylistStatus = requestStatusEnum.PROGRESS;
    },
    actionGetPlaylistSuccess(state, {payload}) {
      state.userPlaylists.getPlaylistStatus = requestStatusEnum.SUCCESS;
      state.userPlaylists.data = payload;
    },
    actionGetPlaylistFailed(state, ) {
      state.userPlaylists.getPlaylistStatus = requestStatusEnum.FAILED;
    },
    actionDeletePlaylist(state, { payload }) {
      state.playlistDeleteStatus = requestStatusEnum.PROGRESS
    },
    actionDeletePlaylistSuccess(state) {
      state.playlistDeleteStatus = requestStatusEnum.SUCCESS
    },
    actionDeletePlaylistFailed(state) {
      state.playlistDeleteStatus = requestStatusEnum.FAILED
    },
    actionGetPlaylistInfo(state, {payload}) {
      state.playlistInfo.getPlaylistInfoStatus = requestStatusEnum.PROGRESS;
    },
    actionGetPlaylistInfoSuccess(state, {payload}) {
      state.playlistInfo.getPlaylistInfoStatus = requestStatusEnum.SUCCESS;
      state.playlistInfo.data = payload;
    },
    actionGetPlaylistInfoFailed(state) {
      state.playlistInfo.getPlaylistInfoStatus = requestStatusEnum.FAILED;
    },
    actionClearPlaylistInfo(state) {
      state.playlistInfo.getPlaylistInfoStatus = requestStatusEnum.INITIAL;
      state.playlistInfo.data = null;
    },
    actionUpdatePlaylist(state, {payload}){
      state.updatePlaylistStatus = requestStatusEnum.PROGRESS;
    },
    actionUpdatePlaylistSuccess(state){
      state.updatePlaylistStatus = requestStatusEnum.SUCCESS;
    },
    actionUpdatePlaylistFailed(state, ){
      state.updatePlaylistStatus = requestStatusEnum.FAILED;
    },
  },
})

export const {
  actionGetMediaStream,
  actionGetMediaStreamSuccess,
  actionGetMediaStreamFailed,
  actionUploadMedia,
  actionUploadMediaSuccess,
  actionUploadMediaFailed,
  actionSetMediaUploadData,
  actionResetMediaUploadData,
  actionCreatePlaylist,
  actionCreatePlaylistSuccess,
  actionCreatePlaylistFailed,
  actionResetPlaylist,
  actionGetPlaylist,
  actionGetPlaylistSuccess,
  actionGetPlaylistFailed,
  actionDeletePlaylist,
  actionDeletePlaylistSuccess,
  actionDeletePlaylistFailed,
  actionGetPlaylistInfo,
  actionGetPlaylistInfoSuccess,
  actionGetPlaylistInfoFailed,
  actionClearPlaylistInfo,
  actionUpdatePlaylist,
  actionUpdatePlaylistSuccess,
  actionUpdatePlaylistFailed
} = mediaReducer.actions;

export default mediaReducer.reducer

