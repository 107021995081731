import React, {ReactNode, useEffect, useMemo, useState} from "react";
import { useNavigate } from "react-router";
import styles from "./CreateCampaign.module.scss";
import { builderNameSectionMode } from "../../../features/BuilderNameSection/BuilderNameSection.types";
import { routes } from "../../../routing/routes";
import { CreateHeader } from "../../../features/CreateHeader/CreateHeader.component";
import Button from "../../../components/Button/Button";
import { ButtonVariantEnum } from "../../../components/Button/button.types";
import { Stepper } from "../../../components/Stepper/Stepper.component";
import { SetupView } from "./SetupView/SetupView.component";
import { ScheduleCampaign } from "./ScheduleCampaign/ScheduleCampaign.component";
import { useCampaignsActions } from "../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../store/campaigns/useCampaignsSelector";
import { useClustersSelector } from "../../../store/cluster/useClusterSelector";
import { useLoginSelector } from "../../../store/login/useLoginSelectors";
import { useClustersActions } from "../../../store/cluster/useClusterActions";
import { requestStatusEnum } from "../../../store/store.types";
import { Spinner } from "../../../components/Spinner/Spinner.component";
import { Tooltip } from '../../../components/Tooltip/Tooltip.component';
import { ScreenChannelSetupView } from "./ScreenChannelSetupView/ScreenChannelSetupView.component";

type ViewType = 'playlist' | 'media' | 'channels' | 'screens';

interface SetupViewProps {
  activeView: 'playlist' | 'media';
  onToggleChange: (view: 'playlist' | 'media') => void;
}

interface ScreenChannelSetupViewProps {
  activeView: 'channels' | 'screens';
  onToggleChange: (view: 'channels' | 'screens') => void;
}

export const CreateCampaign: React.FC = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [inputValue, setInputValue] = useState("");
  const [mediaToggleView, setMediaToggleView] = useState<ViewType>("media");
  const [screenToggleView, setScreenToggleView] = useState<ViewType>("screens");

  const { actionResetSelectedCluster } = useClustersActions();
  const {
    resetCampaigns,
    actionSetSelectedPlaylist,
    actionUpdateCreateCampaignPayload,
    actionUpdateCreateMerchantScreenCampaignPayload,
    actionUpdateCreateMerchantChannelsCampaignPayload,
    actionScheduleCampaign,
    actionScheduleMerchantScreensCampaign,
    actionCampaignSaveAsDraft
  } = useCampaignsActions();

  const {
    selectedMedia,
    selectedScreens,
    selectedChannels,
    selectedDates,
    selectedClusterSlots,
    selectedScreenSlots,
    selectedMediaLength,
    createCampaignPayload,
    createMerchantScreenCampaignPayload,
    campaignScheduleStatus,
    campaignSaveAsDraftStatus,
    campaignMerchantScreenScheduleStatus,
    selectedPlaylist,
    createMerchantChannelCampaignPayload
  } = useCampaignsSelector();

  const { selectedClusters } = useClustersSelector();
  const { userData } = useLoginSelector();
  const campaignType = localStorage.getItem('SelectedCampaignType');

  // Initialize campaign data
  useEffect(() => {
    const basePayload = {
      userId: userData?.userId,
      orgId: userData?.ordId
    };

    actionUpdateCreateCampaignPayload(basePayload);
    actionUpdateCreateMerchantScreenCampaignPayload(basePayload);
    actionUpdateCreateMerchantChannelsCampaignPayload(basePayload);

    actionSetSelectedPlaylist({
      data: 0,
      playlistLength: 0,
      isPlaylist: false,
    });

    return () => {
      resetCampaigns();
      actionResetSelectedCluster();
    };
  }, []);

  // Handle navigation on successful campaign creation
  useEffect(() => {
    if (campaignScheduleStatus === requestStatusEnum.SUCCESS ||
        campaignSaveAsDraftStatus === requestStatusEnum.SUCCESS) {
      navigate(routes().campaign);
    }
  }, [campaignScheduleStatus, campaignSaveAsDraftStatus]);

  // Update campaign payload when input changes
  useEffect(() => {
    if (inputValue) {
      const namePayload = { name: inputValue };
      actionUpdateCreateCampaignPayload(namePayload);
      actionUpdateCreateMerchantScreenCampaignPayload(namePayload);
      actionUpdateCreateMerchantChannelsCampaignPayload(namePayload);
    }
  }, [inputValue]);

  // Update media-related payload
  useEffect(() => {
    if (selectedPlaylist?.data?.length > 0) {
      actionUpdateCreateMerchantChannelsCampaignPayload({
        playlistId: selectedPlaylist.data,
        playlistLengthInSec: selectedPlaylist.playlistLength
      });
    } else if (selectedMedia?.length) {
      const mediaPayload = {
        mediaId: selectedMedia,
        mediaLengthInSec: selectedMediaLength,
      };
      actionUpdateCreateCampaignPayload(mediaPayload);
      actionUpdateCreateMerchantScreenCampaignPayload(mediaPayload);
      actionUpdateCreateMerchantChannelsCampaignPayload(mediaPayload);
    }
  }, [selectedMedia, selectedPlaylist]);

  // Update clusters/screens payload
  useEffect(() => {
    if (selectedClusters) {
      actionUpdateCreateCampaignPayload({ clusters: selectedClusters });
    }
    if (selectedScreens) {
      actionUpdateCreateMerchantScreenCampaignPayload({ screens: selectedScreens });
    }
    if (selectedChannels) {
      actionUpdateCreateMerchantChannelsCampaignPayload({ screens: selectedChannels });
    }
  }, [selectedClusters, selectedScreens, selectedChannels]);

  // Update slots payload with sorting
  useEffect(() => {
    if (campaignType === 'myScreens') {
      if (selectedScreenSlots?.length > 0) {
        // Sort the slots in ascending order
        const sortedSlots = [...selectedScreenSlots].sort((a, b) => parseInt(a) - parseInt(b));

        // Find the actual start and end slots after sorting
        const firstSlot = sortedSlots[0];
        const lastSlot = sortedSlots[sortedSlots.length - 1];

        // Add one hour (3600 seconds) to the last slot for the end time
        const endTimeTimestamp = (parseInt(lastSlot) + 3600).toString();

        const screenSlotsPayload = {
          slots: sortedSlots,
          startTime: firstSlot.toString(),
          endTime: endTimeTimestamp
        };

        actionUpdateCreateMerchantScreenCampaignPayload(screenSlotsPayload);
        actionUpdateCreateMerchantChannelsCampaignPayload(screenSlotsPayload);
      }
    } else if (selectedClusterSlots?.length > 0) {
      // Sort the cluster slots in ascending order
      const sortedClusterSlots = [...selectedClusterSlots].sort((a, b) => parseInt(a) - parseInt(b));

      // Find the actual start and end slots after sorting
      const firstClusterSlot = sortedClusterSlots[0];
      const lastClusterSlot = sortedClusterSlots[sortedClusterSlots.length - 1];

      // Add one hour (3600 seconds) to the last slot for the end time
      const endTimeTimestamp = (parseInt(lastClusterSlot) + 3600).toString();

      actionUpdateCreateCampaignPayload({
        slots: sortedClusterSlots,
        startTime: firstClusterSlot.toString(),
        endTime: endTimeTimestamp
      });
    }
  }, [selectedClusterSlots, selectedScreenSlots]);

  const handleClose = () => {
    localStorage.removeItem('SelectedCampaignType');
    navigate(routes().campaign);
  };

  const handleNext = () => {
    if (currentStep < 3) {
      setCurrentStep(currentStep + 1);
    } else {
      if (campaignType === 'myScreens') {
        if (selectedChannels.length > 0) {
          if (selectedPlaylist?.isPlaylist) {
            const { mediaId, mediaLengthInSec, ...payloadWithoutMedia } = createMerchantChannelCampaignPayload;
            actionScheduleMerchantScreensCampaign(payloadWithoutMedia);
          } else {
            actionScheduleMerchantScreensCampaign(createMerchantChannelCampaignPayload);
          }
        } else {
          actionScheduleMerchantScreensCampaign(createMerchantScreenCampaignPayload);
        }
      } else {
        actionScheduleCampaign(createCampaignPayload);
      }
    }
  };

  const handleBack = () => {
    if (currentStep === 1) handleClose();
    else setCurrentStep(currentStep - 1);
  };

  const validateScreen = useMemo(() => {
    switch (currentStep) {
      case 1:
        return mediaToggleView === 'playlist'
            ? selectedPlaylist?.data?.length > 0
            : selectedMedia?.length > 0;

      case 2:
        if (!inputValue) return false;

        return campaignType === 'myScreens'
            ? (screenToggleView === 'channels'
                ? selectedChannels?.length > 0
                : selectedScreens?.length > 0)
            : selectedClusters?.length > 0;

      case 3:
        if (!inputValue) return false;
        return campaignType === 'myScreens'
            ? selectedScreenSlots?.length > 0
            : selectedClusterSlots?.length > 0;

      default:
        return true;
    }
  }, [
    currentStep,
    inputValue,
    mediaToggleView,
    screenToggleView,
    selectedMedia,
    selectedPlaylist,
    selectedScreens,
    selectedChannels,
    selectedClusters,
    selectedClusterSlots,
    selectedScreenSlots,
  ]);

  const validateSaveAsDraft = useMemo(() => {
    if (!inputValue) return false;
    return currentStep === 1 ? !!selectedMedia : true;
  }, [currentStep, inputValue, selectedMedia]);

  const getTooltipMessage = (step: number): string => {
    switch (step) {
      case 1:
        if (mediaToggleView === 'playlist' && !selectedPlaylist?.data?.length) {
          return "Please select a playlist to proceed";
        }
        if (mediaToggleView === 'media' && !selectedMedia?.length) {
          return "Please select the media. If you don't see the media list, make sure the media is verified";
        }
        break;

      case 2:
        if (!inputValue) {
          return "Please add the campaign name to proceed";
        }
        if (campaignType === 'myScreens') {
          if (screenToggleView === 'channels' && !selectedChannels?.length) {
            return "Please select the channels to proceed";
          }
          if (screenToggleView === 'screens' && !selectedScreens?.length) {
            return "Please select the screens to proceed";
          }
        } else if (!selectedClusters?.length) {
          return "Please select the Cluster to proceed";
        }
        break;

      case 3:
        if (!inputValue) {
          return "Please add the campaign name to proceed";
        }
        if (campaignType === 'myScreens' && !selectedScreenSlots?.length) {
          return "Please select the Screen slots to Schedule campaign";
        }
        if (campaignType !== 'myScreens' && !selectedClusterSlots?.length) {
          return "Please select the Cluster slots to Schedule campaign";
        }
        break;
    }
    return "";
  };

  const steps: Array<{
    label: string;
    component: (props: any) => ReactNode;
  }> = useMemo(() => ([
    {
      label: "Setup",
      component: (props: SetupViewProps) => (
          <SetupView
              activeView={props.activeView}
              onToggleChange={props.onToggleChange}
          />
      )
    },
    {
      label: campaignType === 'myScreens' ? 'Select Screens' : 'Select Cluster',
      component: (props: ScreenChannelSetupViewProps) => (
          <ScreenChannelSetupView
              activeView={props.activeView}
              onToggleChange={props.onToggleChange}
          />
      )
    },
    {
      label: "Schedule",
      component: () => <ScheduleCampaign />
    }
  ]), [campaignType]);

  const CurrentComponent = useMemo(() => {
    const step = steps[currentStep - 1];

    switch (step.label) {
      case "Setup":
        return step.component({
          activeView: mediaToggleView as 'playlist' | 'media',
          onToggleChange: (view: 'playlist' | 'media') => setMediaToggleView(view)
        });

      case "Select Screens":
      case "Select Cluster":
        return step.component({
          activeView: screenToggleView as 'channels' | 'screens',
          onToggleChange: (view: 'channels' | 'screens') => setScreenToggleView(view)
        });

      case "Schedule":
        return step.component({});

      default:
        return null;
    }
  }, [currentStep, mediaToggleView, screenToggleView, steps]);

  const renderFooter = () => (
      <div className={styles.footer}>
        <Button
            variant={ButtonVariantEnum.secondary}
            text="Back"
            onClick={handleBack}
            height="32px"
            width="78px"
        />
        {campaignType === 'screenHub' && (
            <Button
                variant={ButtonVariantEnum.secondary}
                text="Save As Draft"
                onClick={() => actionCampaignSaveAsDraft(createCampaignPayload)}
                height="32px"
                width="120px"
                disabled={!validateSaveAsDraft}
            />
        )}
        <Tooltip
            message={getTooltipMessage(currentStep)}
            show={!validateScreen}
        >
          <Button
              variant={ButtonVariantEnum.primary}
              text={currentStep === 3 ? "Publish" : "Next"}
              onClick={handleNext}
              height="32px"
              width="78px"
              disabled={!validateScreen}
          />
        </Tooltip>
      </div>
  );

  return (
      <div>
        {(campaignMerchantScreenScheduleStatus === requestStatusEnum.PROGRESS ||
            campaignScheduleStatus === requestStatusEnum.PROGRESS) && (
            <div className={styles.campaignLoader}>
              <Spinner />
            </div>
        )}
        <CreateHeader
            headerText="Create New Campaign"
            name={inputValue}
            setName={setInputValue}
            nameFieldPlaceholder="Enter Campaign Name"
            onCloseClick={handleClose}
            mode={builderNameSectionMode.CREATE}
            footer={renderFooter()}
        />
        <div className={styles.currentCompWrapper}>
          <Stepper
              steps={steps}
              currentStep={currentStep}
          />
          {CurrentComponent}
        </div>
      </div>
  );
};