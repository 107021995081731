import classNames from "classnames";
import { FontWeightEnum, Text, TextVariantsEnum } from "../Text";
import styles from "./MediaCard.module.scss";
import { TMediaCardProps } from "./MediaCard.types";
import { capitalize } from "../../utils/capitalize";
import { mediaStatusEnum } from "../../pages/media/Media.constants";

export const MediaCard: React.FC<TMediaCardProps> = ({
                                                         mediaId,
                                                         mediaName,
                                                         mediaSrc,
                                                         mediaTags,
                                                         selected,
                                                         handleSelectMedia,
                                                         status,
                                                         onClickMedia,
                                                         selectedMediaLengthInSec,
                                                         multiSelect
                                                     }) => {
    return (
        <div
            className={classNames(styles.cardWrapper, {
                [styles.selectedCard]: selected,
                [styles.cursor]: onClickMedia || handleSelectMedia
            })}
            onClick={() => {
                handleSelectMedia && handleSelectMedia(mediaId, selectedMediaLengthInSec ?? 0, mediaName, 0, mediaSrc)
                onClickMedia && mediaId && onClickMedia(mediaId)
            }}
        >
            {handleSelectMedia &&
                <div className={styles.selectBtn}>
                    <input
                        type={"radio"}
                        id={mediaId}
                        value={mediaId}
                        checked={selected}
                        onChange={() => null}
                        className={styles.input}
                    />
                </div>
            }
{status &&     <Text
          variant={TextVariantsEnum.Text_xs}
          fontWeight={FontWeightEnum.Semibold}
          className={classNames(styles.status, styles[`status_${status.toLowerCase()}`])}
          color={'white'}
        >
          {capitalize(mediaStatusEnum[status])}
        </Text>}
      <img src={mediaSrc} className={styles.thumbnail} />
      <div className={styles.cardDetail}>
        <Text
          variant={TextVariantsEnum.Text_md}
          fontWeight={FontWeightEnum.Semibold}
        >
          {mediaName}
        </Text>
        <div className={styles.tagWrapper}>
            {mediaTags.map((tag, index) => (
                <Text
                key={index}
                variant={TextVariantsEnum.Text_xs}
                fontWeight={FontWeightEnum.Semibold}
                className={styles.tag}>{tag}</Text>
            ))
            }
        </div>
      </div>
    </div>
  );
};
