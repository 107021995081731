import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Menu, Clock, X } from 'lucide-react';
import styles from './PlaylistContent.module.scss';
import classNames from 'classnames';
import {useParams} from "react-router-dom";

interface PlaylistItem {
    id: string;
    length: number;
    name?: string;
    thumbnailUrl?: string;
}

interface PlaylistContentProps {
    items: PlaylistItem[];
    onReorder: (items: PlaylistItem[]) => void;
    onRemove: (itemId: string) => void;
}

const formatDuration = (seconds: number): string => {
    return `${Math.floor(seconds)} sec`;
};

export const PlaylistContent: React.FC<PlaylistContentProps> = ({ items, onReorder, onRemove }) => {
    const { id: playlistId } = useParams<{ id: string }>();
    const isEditMode = Boolean(playlistId);
    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedItems = Array.from(items);
        const [reorderedItem] = reorderedItems.splice(result.source.index, 1);
        reorderedItems.splice(result.destination.index, 0, reorderedItem);

        onReorder(reorderedItems);
    };

    return (
        <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="playlist">
                {(provided) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={styles.container}
                    >
                        {items.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        className={classNames(styles.item, {
                                            [styles.isDragging]: snapshot.isDragging
                                        })}
                                    >
                                        <div
                                            {...provided.dragHandleProps}
                                            className={styles.dragHandle}
                                        >
                                            <Menu size={20} />
                                        </div>

                                        <div className={styles.thumbnail}>
                                            <img
                                                src={(isEditMode ? `data:image/jpeg;base64,${item.thumbnailUrl}` : item.thumbnailUrl) || `/api/placeholder/96/64`}
                                                alt={item.name || 'Media thumbnail'}
                                                onError={(e) => {
                                                    const img = e.target as HTMLImageElement;
                                                    img.src = '/api/placeholder/96/64';
                                                }}
                                            />
                                        </div>

                                        <div className={styles.content}>
                                            <h3 className={styles.title}>
                                                {item.name || `Media ${index + 1}`}
                                            </h3>
                                        </div>

                                        <div className={styles.duration}>
                                            <Clock size={16} className={styles.icon} />
                                            <span className={styles.time}>
                                                {formatDuration(item.length)}
                                            </span>
                                        </div>

                                        <button
                                            className={styles.removeButton}
                                            onClick={() => onRemove(item.id)}
                                            aria-label="Remove item"
                                        >
                                            <X size={20} />
                                        </button>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};