import { all, call, put, takeLatest } from "redux-saga/effects";
import { MediasService } from "../../services/media/MediaService";
import {
  actionCreatePlaylist,
  actionCreatePlaylistFailed,
  actionCreatePlaylistSuccess,
  actionDeletePlaylist, actionDeletePlaylistFailed,
  actionDeletePlaylistSuccess,
  actionGetMediaStream,
  actionGetMediaStreamFailed,
  actionGetMediaStreamSuccess,
  actionGetPlaylist,
  actionGetPlaylistFailed, actionGetPlaylistInfo, actionGetPlaylistInfoFailed, actionGetPlaylistInfoSuccess,
  actionGetPlaylistSuccess, actionUpdatePlaylist, actionUpdatePlaylistFailed, actionUpdatePlaylistSuccess,
  actionUploadMedia,
  actionUploadMediaFailed,
  actionUploadMediaSuccess,
} from "./media.reducer";
import { actionAddNotification } from "../notification/notification.reducer";
import { auth } from "../../config/firebase";
import { v4 as uuidv4 } from "uuid";
import {TActionGetPlaylist, TActionGetPlaylistInfo} from "./media.types";
import {Media} from "../../pages/media";

const fetchWrapper = async (url: string, customHeaders: Record<string, string> = {}) => {
  const currentUser = auth.currentUser;
  const clientId = process.env.REACT_APP_CLIENT_ID ?? 'default_client_id';
  const requestId = uuidv4();

  if (currentUser) {
    const access_token = await currentUser.getIdToken();

    const headers = {
      Authorization: `Bearer ${access_token}`,
      ClientId: clientId,
      RequestId: requestId,
      ...customHeaders,
    };

    return fetch(url, { headers })
        .then((response) => {
          if (!response.ok) {
            throw new Error(response.statusText);
          }
          return response.blob().then((blob) => ({
            blob,
            statusText: response.statusText,
          }));
        });
  } else {
    throw new Error("User not authenticated");
  }
};

function* fetchVideoUrl(payload: { orgId: string; id: string }) {
  try {
    const url = `${process.env.REACT_APP_GATEWAY_URL}/public/media/stream/org/${payload.orgId}/media/${payload.id}`;

    const { blob } = yield call(fetchWrapper, url);

    const urlCreator = window.URL || window.webkitURL;
    const videoUrl = urlCreator.createObjectURL(blob);

    return videoUrl;
  } catch (error) {
    console.error("Error fetching video URL:", error);
    return null;
  }
}

function* fetchMediaSaga({ payload }: any): any {
  try {
    const videoUrl = yield call(fetchVideoUrl, payload);

    if (videoUrl) {
      yield put(actionGetMediaStreamSuccess(videoUrl));  // Dispatch success with video URL
    } else {
      yield put(actionGetMediaStreamFailed());  // Dispatch failure
    }
  } catch (error) {
    console.error("Error in fetchMediaSaga:", error);
    yield put(actionGetMediaStreamFailed());  // Dispatch failure on error
  }
}

function* uploadMediaSaga({ payload }: any): any {
  try {
    const formdata = new FormData();
    formdata.append("file", payload.file);
    formdata.append("userId", payload.userId);
    formdata.append("orgId", payload.orgId);
    formdata.append("name", payload.mediaName);
    formdata.append("tags", payload.tags);
    formdata.append("length", payload.length);
    formdata.append("thumbnail", payload.thumbnail);


    const response = yield call(MediasService.uploadMedia, formdata);

    yield put(actionUploadMediaSuccess(response));
    yield put(actionAddNotification({
      type: 'success',
      message: 'Media Uploaded Successfully'
    }));
  } catch (error) {
    console.log(error);
    yield put(actionUploadMediaFailed());
    yield put(actionAddNotification({
      type: 'error',
      message: 'Error occurred while uploading Media. Please try again in some time'
    }));
  }
}

function* createPlaylistSaga({ payload }: any) {
  try {
    yield call(MediasService.createPlaylist, payload);
    yield put(actionCreatePlaylistSuccess());
    yield put(actionAddNotification({
      type: 'success',
      message: 'Playlist Created Successfully'
    }));

  } catch (error) {
    yield put(actionCreatePlaylistFailed());
    yield put(actionAddNotification({
      type: 'error',
      message: 'Error occurred while creating Playlist. Please try again in some time'
    }));
  }
}

function* getPlaylistSaga({payload}: TActionGetPlaylist) : any {
  try {
    const response = yield call(MediasService.getPlaylist, payload);
    yield put(actionGetPlaylistSuccess(response?.response?.mediaDetails));
  } catch (error) {
    yield put(actionGetPlaylistFailed());
  }
}

function* getPlaylistInfoSaga({payload}: TActionGetPlaylistInfo): any {
  try {
    const response = yield call(MediasService.getPlaylistInfo, payload);
    yield put(actionGetPlaylistInfoSuccess(response?.response));
  } catch(error) {
    yield put(actionGetPlaylistInfoFailed());
  }
}

function* deletePlaylistSaga({ payload }: any) {
  try {
    yield call(MediasService.deletePlaylist, payload);
    yield put(actionDeletePlaylistSuccess());
    yield put(actionAddNotification({
      type: 'success',
      message: 'Playlist Deleted Successfully'
    }));
  } catch (error) {
    yield put(actionDeletePlaylistFailed());
    yield put(actionAddNotification({
      type: 'error',
      message: 'Error occurred while deleting playlist. Please try again in some time'
    }));
  }
}

function* updatePlaylistSaga({payload}: any) {
  try{
    yield call(MediasService.updatePlaylist, payload);
    yield put(actionUpdatePlaylistSuccess());
    yield put(actionAddNotification({
      type: 'success',
      message: 'Playlist Updated Successfully'
    }));
  }catch(error) {
    yield put(actionUpdatePlaylistFailed());
    yield put(actionAddNotification({
      type: 'error',
      message: 'Error occurred while updating playlist. Please try again in some time'
    }));
  }
}

export function* mediaWatcher() {
  yield all([
    takeLatest(actionGetMediaStream.type, fetchMediaSaga),
    takeLatest(actionUploadMedia.type, uploadMediaSaga),
    takeLatest(actionCreatePlaylist.type, createPlaylistSaga),
    takeLatest(actionGetPlaylist.type, getPlaylistSaga),
    takeLatest(actionDeletePlaylist.type, deletePlaylistSaga),
    takeLatest(actionUpdatePlaylist.type, updatePlaylistSaga),
    takeLatest(actionGetPlaylistInfo.type, getPlaylistInfoSaga),
  ]);
}
