import React, { useEffect, useState } from "react";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { requestStatusEnum } from "../../store/store.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import styles from "./Media.module.scss";
import { MediaCard } from "../../components/MediaCard/MediaCard.component";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../components/Button/button.types";
import Button from "../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import { TablePagination } from "../../components/TablePagination/TablePagination.component";
import { Modal } from "../../components/Modal";
import { UploadMedia } from "./UploadMedia/UploadMedia.component";
import { useMediaActions } from "../../store/media/useMediaActions";
import { useMediaSelector } from "../../store/media/useMediaSelector";
import { Dropdown } from "../../components/Dropdown/Dropdown.component";

export enum MediaStatus {
  VERIFIED = 'VERIFIED',
  UN_VERIFIED = 'UN_VERIFIED',
  REJECTED = 'REJECTED',
  ALL = 'ALL'
}

export const MediaComponent: React.FC = () => {
  const navigate = useNavigate();

  const [startRecord, setStartRecord] = useState(0);
  const [modalStep, setModalStep] = useState(1);
  const [isUploadModalVisible, setUploadModalVisible] = useState(false);
  const [isStreamModalVisible, setStreamModalVisible] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<string>(MediaStatus.ALL);

  const { actionGetMediaStream, actionSetMediaUploadData, actionUploadMedia, actionResetMediaUploadData } = useMediaActions();
  const { mediaUpload: { status: mediaUploadStatus }, media: { data: mediaStream, status: mediaStreamStatus }, mediaDataUploaded } = useMediaSelector();
  const { actionFetchMediaLibrary } = useCampaignsActions();
  const {
    mediaLibrary: {
      data: mediaLibraryData,
      status: mediaLibraryStatus,
      totalPages,
    },
  } = useCampaignsSelector();

  const {
    userData: { ordId: orgId, userId },
  } = useLoginSelector();

  const statusOptions = [
    { label: 'ALL', value: MediaStatus.ALL },
    { label: 'VERIFIED', value: MediaStatus.VERIFIED },
    { label: 'UNVERIFIED', value: MediaStatus.UN_VERIFIED },
    { label: 'REJECTED', value: MediaStatus.REJECTED }
  ];

  // Effect to fetch media library on initial load and filter changes
  useEffect(() => {
    if (orgId) {
      actionFetchMediaLibrary({ page: startRecord, orgId, status: selectedStatus });
    }
  }, [orgId, selectedStatus, startRecord]);

  // Effect to handle successful media upload
  useEffect(() => {
    if (mediaUploadStatus === requestStatusEnum.SUCCESS && orgId) {
      actionFetchMediaLibrary({
        page: startRecord,
        orgId,
        status: MediaStatus.ALL
      });
      setSelectedStatus(MediaStatus.ALL);
    }
  }, [mediaUploadStatus, orgId]);

  const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
    setStartRecord(startRecord);
  };

  const onClose = () => {
    setUploadModalVisible(false);
  };

  const onCloseStreamModal = () => {
    setStreamModalVisible(false);
  };

  const onClickMedia = (id: string) => {
    setStreamModalVisible(true);
    actionGetMediaStream({ orgId, id: id });
  };

  const handleMediaDetails = (field: string, value: string | File) => {
    actionSetMediaUploadData({ [`${field}`]: value });
  };

  const onCancelUpload = () => {
    if (modalStep === 2) {
      setModalStep(1);
    } else {
      onClose();
    }
  };

  const onHandleUpload = () => {
    if (modalStep === 1) {
      setModalStep(2);
    } else {
      actionUploadMedia({
        ...mediaDataUploaded,
        orgId,
        userId
      });
      setUploadModalVisible(false);
      actionResetMediaUploadData();
      setModalStep(1);
    }
  };

  return (
      <>
        <div className={styles.libraryWrapper}>
          {mediaUploadStatus === requestStatusEnum.PROGRESS && (
              <div className={styles.spinnerOverlay}>
                <Spinner />
              </div>
          )}
          <div className={styles.headerWrapper}>
            <Text
                variant={TextVariantsEnum.Display_sm}
                fontWeight={FontWeightEnum.Bold}
                className={styles.header}
            >
              Media Library
            </Text>
            <Button
                icon="./icons/plus.svg"
                iconColor="white"
                iconSize={12}
                text="Add Media"
                onClick={() => setUploadModalVisible(true)}
                variant={ButtonVariantEnum.primary}
                size={ButtonTextSizeEnum.md}
            />
          </div>
          {mediaLibraryStatus === requestStatusEnum.PROGRESS ? (
              <Spinner />
          ) : (
              <div className={styles.mediaWrapper}>
                <div className={styles.filterSection}>
                  <Dropdown
                      options={statusOptions}
                      placeholder="Filter by Status"
                      value={selectedStatus}
                      onChange={setSelectedStatus}
                      width={240}
                      height={28}
                      maxHeight={300}
                      className={styles.statusFilter}
                  />
                </div>
                <div className={styles.separator}/>
                {mediaLibraryData.length > 0 ? (
                    <>
                      <div className={styles.mediaList}>
                        {mediaLibraryData.map((mediaData, index) => (
                            <MediaCard
                                key={`${mediaData.name}-${index}`}
                                mediaId={mediaData.id}
                                mediaName={mediaData.name}
                                mediaSrc={`data:image/jpeg;base64,${mediaData.thumbnail}`}
                                mediaTags={mediaData.tags}
                                status={mediaData.status}
                                onClickMedia={onClickMedia}
                            />
                        ))}
                      </div>
                      <div className={styles.separator}/>
                      <TablePagination
                          totalRecords={totalPages * mediaLibraryData.length}
                          startRecord={startRecord}
                          recordsPerPage={10}
                          onPaginationClick={onPaginationClick}
                      />
                    </>
                ) : (
                    <div className={styles.emptyState}>
                      <Text
                          variant={TextVariantsEnum.Text_md}
                          fontWeight={FontWeightEnum.Normal}
                      >
                        No media found
                      </Text>
                    </div>
                )}
              </div>
          )}
        </div>

        <Modal
            isVisible={isUploadModalVisible}
            onClose={onClose}
            className={styles.modalClass}
        >
          <UploadMedia
              onConfirm={onHandleUpload}
              title={modalStep === 1 ? 'Add Media' : 'Add Media Details'}
              loading={false}
              onCancel={onCancelUpload}
              modelStep={modalStep}
              handleMediaDetails={handleMediaDetails}
              mediaDataUploaded={mediaDataUploaded}
          />
        </Modal>

        <Modal
            isVisible={isStreamModalVisible}
            onClose={onCloseStreamModal}
            className={styles.videoModalClass}
            customStyles={{ maxWidth: '700px', maxHeight: '450px' }}
        >
          <div className={styles.videoModalContent}>
            <div className={styles.videoWrapper}>
              {mediaStreamStatus === requestStatusEnum.PROGRESS ? (
                  <div className={styles.spinnerWrapper}>
                    <Spinner/>
                  </div>
              ) : (
                  <video
                      className={styles.video}
                      controls
                      playsInline
                  >
                    <source src={mediaStream} type="video/mp4"/>
                    Your browser does not support the video tag.
                  </video>
              )}
            </div>
          </div>
        </Modal>
      </>
  );
};