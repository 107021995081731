import React, { useEffect, useState } from "react";
import { Table } from "../../../../components/Table/Table.component";
import { useClustersActions } from "../../../../store/cluster/useClusterActions";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";
import { useClustersSelector } from "../../../../store/cluster/useClusterSelector";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { Checkbox } from "../../../../components/Checkbox/Checkbox.component";
import { Text, TextVariantsEnum, FontWeightEnum } from "../../../../components/Text";
import { requestStatusEnum } from "../../../../store/store.types";
import { StatusBadge } from "../../../../components/StatusBadge/StatusBadge.component";
import styles from './SelectChannel.module.scss';
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";

export const SelectChannel = () => {
    const [tableData, setTableData] = useState<any>([]);
    const [startRecord, setStartRecord] = useState(0);

    const { selectedChannels: selectedChannelArray } = useCampaignsSelector();
    const { userData: { ordId: orgId, userId } } = useLoginSelector();
    const { actionGetChannelsByUser } = useClustersActions();
    const { actionSetSelectedChannels } = useCampaignsActions();
    const { userChannel: { userChannelStatus: status, data } } = useClustersSelector();

    useEffect(() => {
        actionGetChannelsByUser({ userId, page: startRecord / 20 });
    }, [userId, startRecord]);

    useEffect(() => {
        if (status === requestStatusEnum.SUCCESS && data) {
            setTableData(data);
        }
    }, [status, data]);

    const onPaginationClick = (startRecord: number) => {
        actionGetChannelsByUser({ userId, page: startRecord / 20 });
        setStartRecord(startRecord);
    };

    const onSelectChannel = (channelId: string) => {
        const updatedChannels = selectedChannelArray.includes(channelId)
            ? selectedChannelArray.filter(id => id !== channelId)
            : [...selectedChannelArray, channelId];

        actionSetSelectedChannels(updatedChannels);
    };

    const getTableColumns = () => [
        {
            id: 'name',
            name: 'Channel Name',
            cellRenderer: (data: any) => (
                <div className={styles.cellRenderer}>
                    <Checkbox
                        checked={selectedChannelArray.includes(data.id)}
                        id={`channel-checkbox-${data.id}`}
                        onClick={() => onSelectChannel(data.id)}
                    />
                    <Text
                        variant={TextVariantsEnum.Text_md}
                        fontWeight={FontWeightEnum.Normal}
                        color="neutral-70"
                    >
                        {data.name}
                    </Text>
                </div>
            )
        },
        {
            id: 'status',
            name: 'Status',
            cellRenderer: (data: any) => (
                <StatusBadge status={data.status?.toLowerCase() || 'offline'} />
            )
        },
        {
            id: 'tags',
            name: 'Tags',
            cellRenderer: (data: any) => (
                <div className={styles.tagsContainer}>
                    {data.tags?.map((tag: string, index: number) => (
                        <span
                            key={`${data.id}-tag-${index}`}
                            className={styles.tag}
                        >
                            {tag}
                        </span>
                    ))}
                </div>
            )
        }
    ];

    const isLoading = status === requestStatusEnum.PROGRESS;

    return (
        <>
            {isLoading ? (
                <Spinner />
            ) : (
                <div className={styles.channelWrapper}>
                    <Table
                        data={tableData}
                        columns={getTableColumns()}
                        isLoading={false}
                        totalRecords={tableData?.length}
                        startRecord={startRecord}
                        recordsPerPage={20}
                        onPaginationClick={onPaginationClick}
                        emptyState="No Channels Found"
                        className={styles.channelTable}
                    />
                </div>
            )}
        </>
    );
};

export default SelectChannel;