import { api } from "..";

export const ClustersService = {
    getClusters: (payload: {orgId: string; page: number}) =>
        api.get(`/public/screen/cluster/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {userId:number, page: number}) =>
        api.get(`public/screen/user/${payload.userId}/type/TV/page/${payload.page}`).then((data) => data?.data),

    createChannel: (payload: any) => api.post('/public/screen/register/v2', payload?.payload).then((data) => data?.data),

    getChannels: (payload: any) => api.get(`public/screen/user/${payload?.userId}/type/CHANNEL/page/${payload?.page}`).then(),

    addScreen: (payload: any) => api.post(`public/screen/register/v2`, payload).then((data) => data?.data),

    createCluster: (payload: {orgId:string;
        name: string;
    screens: Array<string>}) => 
        api.post(`/public/screen/cluster/create`,payload).then((data) => data?.data),
}