import styles from './SelectContentModal.module.scss';
import React, { useState } from "react";
import { SelectMultipleMedia } from "../SelectMultipleMedia/SelectMultipleMedia.component";
import { Text } from "../../../../components/Text";
import { TextVariantsEnum, FontWeightEnum } from "../../../../components/Text";
import Button from "../../../../components/Button/Button";
import {useParams} from "react-router-dom";

interface SelectedMediaItem {
    id: string;
    length: number;
    name?: string;
    size?: number;
    thumbnailUrl?: string;
}

interface SelectContentModalProps {
    onItemsAdd: (items: SelectedMediaItem[]) => void;
}

export const SelectContentModalComponent: React.FC<SelectContentModalProps> = ({ onItemsAdd }) => {
    const [selectedItems, setSelectedItems] = useState<SelectedMediaItem[]>([]);
    const handleSelectionChange = (items: SelectedMediaItem[]) => {
        setSelectedItems(items);
    };

    const handleAddItems = () => {
        onItemsAdd(selectedItems);
    };

    return (
        <div className={styles.modalContainer}>
            <div className={styles.header}>
                <Text
                    variant={TextVariantsEnum.Text_xl}
                    fontWeight={FontWeightEnum.Semibold}
                >
                    Content
                </Text>
            </div>
            <div className={styles.SelectContentModalWrapper}>
                <SelectMultipleMedia onSelectionChange={handleSelectionChange} />
            </div>
            <div className={styles.footer}>
                <Button
                    onClick={handleAddItems}
                    className={styles.addButton}
                    text={`Add ${selectedItems.length} Items`}
                    disabled={selectedItems?.length === 0}
                />
            </div>
        </div>
    );
};