import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table/Table.component";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import styles from "./campaign.module.scss";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../store/store.types";
import { TCampaignListData, TMerchantScreenCampaignListData } from "../../store/campaigns/campaigns.types";
import { CampaignsTableColumns, MerchantScreenCampaignsTableColumns } from "./campaign.config";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { useNavigate } from "react-router";
import { routes } from "../../routing/routes";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Modal } from "../../components/Modal";
import { SelectCampaignType } from "./SelectCampaignType/SelectCampaignType.component";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { Dropdown } from "../../components/Dropdown/Dropdown.component";

type CombinedCampaignData = TCampaignListData | TMerchantScreenCampaignListData;

export const CampaignComponent = () => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState<CombinedCampaignData[]>([]);
  const [startRecord, setStartRecord] = useState<number>(0);
  const [isCreateCampaignModalVisible, setIsCreateCampaignModalVisible] = useState<boolean>(false);
  const [selectedOption, setSelectedOption] = useState<string>("My Screens");
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {
    actionFetchCampaigns,
    actionFetchMerchantScreenCampaigns,
    actionCancelCampaign,
      actionGetCampaignInfo
  } = useCampaignsActions();

  const {
    campaignsList: { status: campaignsListStatus, data: campaignsList },
    merchantScreenCampaignsList: { status: merchantScreenCampaignsListStatus, data: merchantScreenCampaignsList },
    cancelCampaignStatus
  } = useCampaignsSelector();

  const { userData } = useLoginSelector();

  const { actionSetSelectedCampaignType } = useCampaignsActions();

  useEffect(() => {
    if (userData.ordId) {
      if (selectedOption === "My Screens") {
        actionFetchMerchantScreenCampaigns({ page: startRecord, orgId: userData?.ordId });
      } else {
        actionFetchCampaigns({ page: startRecord, orgId: userData?.ordId });
      }
    }
  }, [userData, selectedOption, startRecord]);

  useEffect(() => {
    if (cancelCampaignStatus === requestStatusEnum.SUCCESS) {
      if (userData?.ordId) {
        if (selectedOption === "My Screens") {
          actionFetchMerchantScreenCampaigns({ page: startRecord, orgId: userData?.ordId });
        } else {
          actionFetchCampaigns({ page: startRecord, orgId: userData?.ordId });
        }
      }
    }
  }, [cancelCampaignStatus]);

  useEffect(() => {
    if (selectedOption === "My Screens" && merchantScreenCampaignsListStatus === requestStatusEnum.SUCCESS) {
      setTableData(merchantScreenCampaignsList);
    } else if (selectedOption === "Screen Hub" && campaignsListStatus === requestStatusEnum.SUCCESS) {
      setTableData(campaignsList);
    }
  }, [selectedOption, merchantScreenCampaignsListStatus, campaignsListStatus]);

  const onPaginationClick = (newStartRecord: number) => {
    const pageNumber = Math.floor(newStartRecord / 10);
    setStartRecord(pageNumber);
    
    if (userData?.ordId) {
      if (selectedOption === "My Screens") {
        actionFetchMerchantScreenCampaigns({ 
          page: pageNumber,
          orgId: userData?.ordId 
        });
      } else {
        actionFetchCampaigns({ 
          page: pageNumber,
          orgId: userData?.ordId 
        });
      }
    }
  };

  const onNameClick = (name: string) => {
    console.log("name", name);
  };

  const onCloseCreateCampaignModal = () => {
    setIsCreateCampaignModalVisible(false);
  };

  const handleCreateCampaignButton = () => {
    setDropdownOpen(false);
    /*if (userData?.type === 'MERCHANT') {
      setIsCreateCampaignModalVisible(true);
    } else {*/
      actionSetSelectedCampaignType('myScreens');
      navigate(routes().createCampaign);
    // }
  };

  const handleDropdownChange = (value: string) => {
    setSelectedOption(value);
    setStartRecord(0);
    setDropdownOpen(false);
  };

  const handleResumeCampaign = (id: string) => {
    actionGetCampaignInfo({
      orgId: userData?.ordId,
      campaignId: id
    });
  };

  const handleCancelCampaign = (id: string) => {
    actionCancelCampaign({
      id: id,
      userId: userData?.userId,
      reason: 'CANCEL'
    });
  };

  const dropdownOptions = [
    { label: "My Screens", value: "My Screens" },
    { label: "Screen Hub", value: "Screen Hub" },
  ];

  return (
      <div className={styles.campaignWrapper}>
        <div className={styles.headerWrapper}>
          <Text
              variant={TextVariantsEnum.Display_sm}
              fontWeight={FontWeightEnum.Bold}
              className={styles.header}
          >
            Campaigns
          </Text>
          <div className={styles.headerButtonsWrapper}>
            <div className={styles.dropdownWrapper}>
              {userData?.type === 'MERCHANT' && (
                  <Dropdown
                      options={dropdownOptions}
                      placeholder="Select Campaign Type"
                      value={selectedOption}
                      onChange={handleDropdownChange}
                      isOpen={dropdownOpen}
                      onOpenChange={setDropdownOpen}
                  />
              )}
            </div>
            <Button
                icon={"./icons/plus.svg"}
                iconColor="white"
                iconSize={12}
                text={"Create Campaign"}
                onClick={handleCreateCampaignButton}
                variant={ButtonVariantEnum.primary}
                size={ButtonTextSizeEnum.md}
            />
          </div>
        </div>
        {(merchantScreenCampaignsListStatus === requestStatusEnum.PROGRESS || campaignsListStatus === requestStatusEnum.PROGRESS) ? (
            <Spinner />
        ) : (selectedOption === "My Screens" ? (
            <Table
                data={tableData}
                columns={MerchantScreenCampaignsTableColumns(
                    onNameClick,
                    handleResumeCampaign,
                    handleCancelCampaign
                )}
                totalRecords={10}
                startRecord={startRecord * 10}
                onPaginationClick={onPaginationClick}
                emptyState={"No Campaign Found"}
            />
        ) : (
            <Table
                data={tableData}
                columns={CampaignsTableColumns(
                    onNameClick,
                    handleResumeCampaign,
                    handleCancelCampaign
                )}
                totalRecords={10}
                startRecord={startRecord * 10}
                onPaginationClick={onPaginationClick}
                emptyState={"No Campaign Found"}
            />
        ))}
        <Modal
            isVisible={isCreateCampaignModalVisible}
            onClose={onCloseCreateCampaignModal}
            className={styles.modalClass}
            customStyles={{ maxWidth: '500px', maxHeight: '415px' }}
        >
          <SelectCampaignType
              mainTitle={'Select Campaign Type'}
              title1={'My Screens'}
              title2={'Screen Hub'}
              onCancel={onCloseCreateCampaignModal}
              loading={false}
          />
        </Modal>
      </div>
  );
};