import React, { useEffect, useState, useCallback } from "react";
import styles from "./channels.module.scss";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { Modal } from "../../components/Modal";
import { requestStatusEnum } from "../../store/store.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { useClustersActions } from "../../store/cluster/useClusterActions";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { useClustersSelector } from "../../store/cluster/useClusterSelector";
import { Table } from "../../components/Table/Table.component";
import { ChannelsTableColumns, TChannelData } from "./channels.config";

export const ChannelsComponent: React.FC = () => {
    const [isCreateChannelModalVisible, setIsCreateChannelModalVisible] = useState(false);
    const [channelNameValue, setChannelNameValue] = useState<string>('');
    const [tagsValue, setTagsValue] = useState<string>('');
    const [tableData, setTableData] = useState<TChannelData[]>([]);
    const [startRecord, setStartRecord] = useState(0);

    const { actionCreateChannel, actionResetChannelCreateStatus, actionGetChannelsByUser } = useClustersActions();
    const { userChannel, createChannel } = useClustersSelector();
    const { userData } = useLoginSelector();

    // Memoized fetch channels function to prevent recreation on each render
    const fetchChannels = useCallback(() => {
        if (userData?.userId) {
            actionGetChannelsByUser({ userId: userData.userId, page: startRecord });
        }
    }, [userData?.userId, startRecord, actionGetChannelsByUser]);

    // Single effect to handle all channel fetching scenarios
    useEffect(() => {
        // Initial load
        if (userChannel.userChannelStatus === requestStatusEnum.INITIAL) {
            fetchChannels();
        }

        // Handle successful channel creation
        if (createChannel?.createChannelStatus === requestStatusEnum.SUCCESS) {
            fetchChannels();
            actionResetChannelCreateStatus();
        }
    }, [
        createChannel?.createChannelStatus,
        userChannel.userChannelStatus,
        fetchChannels,
        actionResetChannelCreateStatus
    ]);

    // Update table data when channels are fetched
    useEffect(() => {
        if (userChannel.userChannelStatus === requestStatusEnum.SUCCESS && userChannel.data) {
            setTableData(Array.isArray(userChannel.data) ? userChannel.data : [userChannel.data]);
        }
    }, [userChannel]);

    const handleCreateChannel = () => {
        setIsCreateChannelModalVisible(true);
    };

    const onClose = () => {
        setIsCreateChannelModalVisible(false);
        setChannelNameValue('');
        setTagsValue('');
    };

    const handleTagsInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTagsValue(e.target.value);
    };

    const handleChannelCreateMethod = () => {
        const tagsArray = tagsValue
            .split(',')
            .map(tag => tag.trim())
            .filter(tag => tag.length > 0);

        const payload = {
            name: channelNameValue,
            orgId: userData?.ordId,
            type: "CHANNEL",
            userId: userData?.userId,
            tags: tagsArray,
            status: "ACTIVE"
        };
        actionCreateChannel(payload);
        setIsCreateChannelModalVisible(false);
    };

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
    };

    return (
        <div className={styles.channelsWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    {'Channels'}
                </Text>
                <div className={styles.headerButtonsWrapper}>
                    <Button
                        icon={"./icons/plus.svg"}
                        iconColor="white"
                        iconSize={12}
                        text={"Create Channel"}
                        onClick={handleCreateChannel}
                        variant={ButtonVariantEnum.primary}
                        size={ButtonTextSizeEnum.md}
                        className={styles.createChannelButton}
                    />
                </div>
            </div>

            {(createChannel?.createChannelStatus === requestStatusEnum.PROGRESS || userChannel.userChannelStatus === requestStatusEnum.PROGRESS) ? (
                <Spinner />
            ) : (
                <Table
                    data={tableData}
                    columns={ChannelsTableColumns(userData || {})}
                    isLoading={false}
                    totalRecords={20}
                    startRecord={startRecord}
                    recordsPerPage={20}
                    onPaginationClick={onPaginationClick}
                    emptyState={"No Channels Found"}
                />
            )}

            <Modal
                isVisible={isCreateChannelModalVisible}
                onClose={onClose}
                className={styles.modalClass}
            >
                <div className={styles.modalContent}>
                    <h2 className={styles.modalHeader}>Add Channel Details</h2>
                    <hr className={styles.modalDivider} />
                    <input
                        type="text"
                        placeholder="Channel Name"
                        value={channelNameValue}
                        onChange={(e) => setChannelNameValue(e.target.value)}
                        className={styles.channelNameInput}
                    />
                    <input
                        type="text"
                        placeholder="Tags (Use comma separated values)"
                        value={tagsValue}
                        onChange={handleTagsInputChange}
                        className={styles.tagsInput}
                    />
                    <div className={styles.modalButtonsWrapper}>
                        <Button
                            text="Back"
                            variant={ButtonVariantEnum.secondary}
                            onClick={onClose}
                            className={styles.backButton}
                        />
                        <Button
                            size={"md"}
                            text="Create"
                            variant={ButtonVariantEnum.primary}
                            onClick={handleChannelCreateMethod}
                            className={styles.createButton}
                        />
                    </div>
                </div>
            </Modal>
        </div>
    );
};