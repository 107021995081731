import React, { useEffect, useState } from "react";
import styles from "./Playlist.module.scss";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import { Dropdown } from "../../components/Dropdown/Dropdown.component";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { Modal } from "../../components/Modal";
import { requestStatusEnum } from "../../store/store.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import {useNavigate} from "react-router";
import {routes} from "../../routing/routes";
import {useMediaActions} from "../../store/media/useMediaActions";
import {actionDeletePlaylist, actionGetPlaylist, actionGetPlaylistInfo} from "../../store/media/media.reducer";
import {useLoginSelector} from "../../store/login/useLoginSelectors";
import {useMediaSelector} from "../../store/media/useMediaSelector";
import {PlaylistTableColumns, TPlaylistData} from "./Playlist.config";
import { Table } from "../../components/Table/Table.component";

type TCopyPlaylistInfo = {
    userId: string;
    name: string;
    mediaList: any
};

export const PlaylistComponent: React.FC = () => {
    const navigate = useNavigate();
    const [tableData, setTableData] = useState<TPlaylistData[]>([]);
    const [startRecord, setStartRecord] = useState(0);
    const [isCopyPlaylistModalVisible, setIsCopyPlaylistModalVisible] = useState(false);
    const [copyPlaylistInputValue, setCopyPlaylistInputValue] = useState("");
    const [copyPlaylistId, setCopyPlaylistId] = useState("");
    const [copyPlaylistInfo, setCopyPlaylistInfo] = useState<TCopyPlaylistInfo>({
        userId: '',
        name: '',
        mediaList: []
    });

    const { userData } = useLoginSelector();
    const { actionGetPlaylist, actionCreatePlaylist, actionDeletePlaylist, actionGetPlaylistInfo, actionClearPlaylistInfo } = useMediaActions();
    const { userPlaylists, playlistCreate, playlistDeleteStatus, playlistInfo } = useMediaSelector();

    useEffect(() => {
        if (userData?.userId) {
            actionGetPlaylist({
                userId: userData?.userId,
                page: startRecord
            });
        }
    }, [userData?.userId, startRecord]);

    useEffect(() => {
        if (userPlaylists?.getPlaylistStatus === requestStatusEnum.SUCCESS && userPlaylists.data) {
            setTableData(Array.isArray(userPlaylists.data) ? userPlaylists.data : [userPlaylists.data]);
        }
    }, [userPlaylists]);

    useEffect(() => {
       playlistInfo.getPlaylistInfoStatus === requestStatusEnum.SUCCESS && setCopyPlaylistInfo({
           userId: userData?.userId,
           name: copyPlaylistInputValue,
           mediaList: playlistInfo.data.mediaDetails
       })
    }, [playlistInfo, copyPlaylistInputValue]);

    console.log(copyPlaylistInfo);

    const handleCreatePlaylist = () => {
        navigate(routes().createPlaylist);
    };

    useEffect(() => {
        (playlistDeleteStatus === requestStatusEnum.SUCCESS || playlistCreate?.playlistCreateStatus === requestStatusEnum.SUCCESS) && actionGetPlaylist({
            userId: userData?.userId,
            page: startRecord
        });
    }, [playlistDeleteStatus, playlistCreate?.playlistCreateStatus]);

    useEffect(() => {
        playlistCreate?.playlistCreateStatus === requestStatusEnum.SUCCESS && setIsCopyPlaylistModalVisible(false);
        playlistCreate?.playlistCreateStatus === requestStatusEnum.SUCCESS && setCopyPlaylistInputValue('');
    }, [playlistCreate?.playlistCreateStatus]);

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
        if (userData?.userId) {
            actionGetPlaylist({
                userId: userData?.userId,
                page: startRecord / recordsPerPage
            });
        }
    };

    const handleEditPlaylist = (playlistId: string) => {
        navigate(routes().editPlaylist.replace(':id', playlistId));
    };

    const handleCopyPlaylist = (playlistId: string) => {
        console.log('Copying playlist:', playlistId);
        setCopyPlaylistId(playlistId);
        actionGetPlaylistInfo({
            id: playlistId,
            userId: userData?.userId,
        });
        setIsCopyPlaylistModalVisible(true);
    };

    const onCloseCopyModal = () => {
        setIsCopyPlaylistModalVisible(false);
        actionClearPlaylistInfo();
    };

    const handleDeletePlaylist = (playlistId: string) => {
        actionDeletePlaylist({
            userId: userData?.userId,
            id: playlistId,
        });
        console.log('Deleting playlist:', playlistId);
    };

    const handlePlaylistCopyMethod = () => {
        actionCreatePlaylist(copyPlaylistInfo);
    };

    return (
        <div className={styles.playlistWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    {'Playlist'}
                </Text>
                <div className={styles.headerButtonsWrapper}>
                    <Button
                        icon={"./icons/plus.svg"}
                        iconColor="white"
                        iconSize={12}
                        text={"Create Playlist"}
                        onClick={handleCreatePlaylist}
                        variant={ButtonVariantEnum.primary}
                        size={ButtonTextSizeEnum.md}
                        className={styles.createPlaylistButton}
                    />
                </div>
            </div>

            {userPlaylists?.getPlaylistStatus === requestStatusEnum.PROGRESS ? (
                <Spinner />
            ) : (
                <Table
                    data={tableData}
                    columns={PlaylistTableColumns(
                        handleEditPlaylist,
                        handleCopyPlaylist,
                        handleDeletePlaylist
                    )}
                    isLoading={false}
                    totalRecords={tableData.length}
                    startRecord={startRecord}
                    recordsPerPage={20}
                    onPaginationClick={onPaginationClick}
                    emptyState={"No Playlists Found"}
                />
            )}
            <Modal
                isVisible={isCopyPlaylistModalVisible}
                onClose={onCloseCopyModal}
                className={styles.modalClass}
            >
                {playlistCreate?.playlistCreateStatus === requestStatusEnum.PROGRESS ? (
                    <Spinner/>
                ) : (
                    <div className={styles.modalContent}>
                        <h2 className={styles.modalHeader}>Add Channel Details</h2>
                        <hr className={styles.modalDivider}/>
                        <input
                            type="text"
                            placeholder="Playlist Name"
                            value={copyPlaylistInputValue}
                            onChange={(e) => setCopyPlaylistInputValue(e.target.value)}
                            className={styles.tagsInput}
                        />
                        <div className={styles.modalButtonsWrapper}>
                            <Button
                                text="Back"
                                variant={ButtonVariantEnum.secondary}
                                onClick={onCloseCopyModal}
                                className={styles.backButton}
                            />
                            <Button
                                size={"md"}
                                text="Create"
                                variant={ButtonVariantEnum.primary}
                                onClick={handlePlaylistCopyMethod}
                                className={styles.createButton}
                            />
                        </div>
                    </div>
                )}
            </Modal>
        </div>
    );
};
