import React from 'react';
import { FontWeightEnum, Text, TextVariantsEnum } from "../Text";
import { TStepperProps } from "./Stepper.types";
import styles from './Stepper.module.scss';
import classNames from "classnames";

export const Stepper: React.FC<TStepperProps> = ({
                                                     steps,
                                                     currentStep,
                                                     onStepClick
                                                 }) => {
    return (
        <div className={styles.stepperContainer}>
            {steps.map((step, index) => (
                <div
                    key={`step-${index}`}
                    className={classNames(styles.stepWrapper, {
                        [styles.step]: !!onStepClick
                    })}
                    onClick={() => onStepClick?.(index + 1)}
                >
                    <Text
                        variant={TextVariantsEnum.Text_xs}
                        className={classNames(styles.stepCount, {
                            [styles.currentStep]: currentStep === index + 1
                        })}
                        color={currentStep === index + 1 ? 'white' : 'black'}
                    >
                        {index + 1}
                    </Text>
                    <Text
                        variant={TextVariantsEnum.Text_sm}
                        color={currentStep === index + 1 ? 'black' : 'neutral-50'}
                        fontWeight={currentStep === index + 1 ? FontWeightEnum.Bold : FontWeightEnum.Normal}
                    >
                        {step.label}
                    </Text>
                </div>
            ))}
        </div>
    );
};