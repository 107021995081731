import { api } from "..";

export const MediasService = {   
    uploadMedia: (payload: any) =>
        api.post(`/public/media/upload`, payload).then((data) => data?.data),
    
    getMedia: (payload: {fileName: string}) =>
        api.get(`/public/media/stream?fileName=${payload.fileName}`).then((data) => data?.data),

    createPlaylist: (payload: any) =>
        api.post(`/public/media/playlist/create`, payload).then((data) => data?.data),

    getPlaylist: (payload: any) => api.get(`/public/media/playlist/user/${payload.userId}/page/${payload.page}`).then((data) => data?.data),

    getPlaylistInfo: (payload: any) => api.get(`/public/media/playlist/id/${payload.id}/user/${payload.userId}`).then((data) => data?.data),

    deletePlaylist: (payload: any) => api.post(`/public/media/playlist/delete`, payload).then((data) => data?.data),

    updatePlaylist: (payload: any) => api.post(`public/media/playlist/update`, payload).then((data) => data?.data),
}