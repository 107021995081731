export const routes = (params?: string) => ({
    home: '/',
    signup: `/signup`,
    login:'/login',
    overview: '/overview',
    media: '/media',
    campaign: '/campaign',
    createCampaign: '/create-campaign',
    cluster: '/cluster',
    createCluster: '/create-cluster',
    screens: '/screens',
    settings: '/account',
    forgotPassword: '/forgot-password',
    channels: '/channels',
    playlist: '/playlist',
    createPlaylist: '/create-playlist',
    channelPlayer: '/channel-player',
    editPlaylist: '/playlist/edit/:id',
    /*account: '/settings/account',
    billing: '/settings/billing',*/
})