import {createSlice} from '@reduxjs/toolkit';
import {TActionLoginWithEmailPayload, TLoginReducer} from './login.types';
import {requestStatusEnum} from '../store.types';
import {NAV_ITEM_NAME} from '../../features/Navbar/Navbar.utils';


const initialState: TLoginReducer = {
  form: {
    email: '',
    password: '',
    remember_me: false,
    status: requestStatusEnum.INITIAL
  },
    AccountDeleteStatus:  requestStatusEnum.INITIAL,
    userDataById: {
      fetchStatus: requestStatusEnum.INITIAL,
        data: {
            userId: '',
            name: '',
            email: '',
            type: '',
            role: '',
            status: '',
            organization: {
                orgId: '',
                name: '',
                type: '',
            },
            address: {
                line1: null,
                line2: null,
                city: null,
                state: null,
                country: null,
                zip: null,
            },
            category: null,
            subCategory: null,
            socialIndicator: null,
        }
    },
  error: { email: '', password: '' },
    /*data: {
        userId: '',
        name: '',
        email: '',
        type: '',
        role: '',
        status: '',
        organization: {
            orgId: '',
            name: '',
            type: '',
        },
        address: {
            line1: null,
            line2: null,
            city: null,
            state: null,
            country: null,
            zip: null,
        },
        category: null,
        subCategory: null,
        socialIndicator: null,
    },*/
  userData: {
    accountStatus: '',
    ordId: '',
    role: '',
    userId: '',
      type: '',
    name: '',
  },
  currentNav: NAV_ITEM_NAME.OVERVIEW
};

const loginReducer = createSlice({
  name: 'loginReducer',
  initialState,
  reducers: {
    actionSubmitLogin(state, { payload }: TActionLoginWithEmailPayload) {
      state.form.status = requestStatusEnum.PROGRESS;
    },
    actionSubmitLoginSuccess(state, {payload}) {
      state.form.status = requestStatusEnum.SUCCESS;
      state.userData = payload.data
    },
    actionSubmitLoginFailed(state) {
      state.form.status = requestStatusEnum.FAILED;
    },
      actionUpdateUser(state , {payload}) {
        state.AccountDeleteStatus =  requestStatusEnum.PROGRESS;
      },
      actionUpdateUserSuccess(state ) {
          state.AccountDeleteStatus =  requestStatusEnum.SUCCESS;
      },
      actionUpdateUserFailed(state ) {
          state.AccountDeleteStatus =  requestStatusEnum.FAILED;
      },
    submitLoginError(state, { payload }) {
      state.form.status = requestStatusEnum.FAILED;
      state.error = payload;
    },
    // updateLoginInput(state, { payload }: TUpdateLoginInput) {
    //   state.form[payload.field] = payload.value;
    // },
    // updateLoginCheckbox(state, { payload }: TUpdateLoginCheckbox) {
    //   state.form.remember_me = payload;
    // },
    actionSetUserData(state, {payload}){
state.userData = payload
    },
    actionSetActiveNav(state, {payload}){
      state.currentNav = payload
    },
      actionGetUserInfoById(state, {payload}){
        state.userDataById.fetchStatus = requestStatusEnum.PROGRESS;
      },
      actionGetUserInfoByIdSuccess(state, {payload}){
          state.userDataById.fetchStatus = requestStatusEnum.SUCCESS;
          state.userDataById.data = payload?.data;
      },
      actionGetUserInfoByIdFailed(state, {payload}){
          state.userDataById.fetchStatus = requestStatusEnum.FAILED;
      },
    actionLogout(state) {
      return initialState;
    },
      resetLogin() {
          return initialState;
      },
  },
})

export const {
    actionSubmitLogin,
    actionSubmitLoginSuccess,
    actionSubmitLoginFailed,
    actionSetUserData,
    actionSetActiveNav,
    actionLogout,
    actionGetUserInfoById,
    actionGetUserInfoByIdSuccess,
    actionGetUserInfoByIdFailed,
    actionUpdateUser,
    actionUpdateUserSuccess,
    actionUpdateUserFailed
} = loginReducer.actions;

export default loginReducer.reducer

