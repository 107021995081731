import React, { useEffect } from 'react';
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";
import { useMediaActions } from "../../../../store/media/useMediaActions";
import { useMediaSelector } from "../../../../store/media/useMediaSelector";
import { requestStatusEnum } from "../../../../store/store.types";
import styles from './SelectPlaylist.module.scss';
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";

const formatDuration = (seconds: number): string => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

interface PlaylistCardProps {
    playlist: {
        id: string;
        name: string;
        totalLength: number;
        mediaDetails: Array<{
            mediaId: string;
            thumbnail: string;
        }>;
    };
    selected: boolean;
    onSelect: (id: string) => void;
}

const PlaylistCard: React.FC<PlaylistCardProps> = ({ playlist, selected, onSelect }) => {
    const thumbnails = playlist.mediaDetails.slice(0, 4);
    const remainingCount = Math.max(0, playlist.mediaDetails.length - 4);

    const handleClick = () => {
        onSelect(playlist.id);
    };

    return (
        <div
            className={`${styles.card} ${selected ? styles.selected : ''}`}
            onClick={handleClick}
            role="button"
            tabIndex={0}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                    handleClick();
                }
            }}
        >
            <div className={styles.radioContainer} onClick={(e) => e.stopPropagation()}>
                <input
                    type="radio"
                    checked={selected}
                    onChange={handleClick}
                />
            </div>

            <div className={styles.thumbnailGrid}>
                {thumbnails.map((media, index) => (
                    <div key={media.mediaId} className={styles.thumbnail}>
                        <img
                            src={`data:image/jpeg;base64,${media.thumbnail}`}
                            alt="Media thumbnail"
                        />
                        {index === 3 && remainingCount > 0 && (
                            <div className={styles.thumbnailOverlay}>
                                <span>+{remainingCount}</span>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className={styles.playlistInfo}>
                <div className={styles.playlistDetails}>
                    <h3>{playlist.name}</h3>
                </div>
                <span className={styles.duration}>
                    {formatDuration(playlist.totalLength)}
                </span>
            </div>
        </div>
    );
};

export const SelectPlaylist: React.FC = () => {
    const [startRecord] = React.useState(0);

    const { actionSetSelectedPlaylist } = useCampaignsActions();
    const { actionGetPlaylist } = useMediaActions();
    const { userData: { ordId: orgId, userId } } = useLoginSelector();
    const { selectedPlaylist } = useCampaignsSelector();
    const { userPlaylists } = useMediaSelector();

    useEffect(() => {
        actionGetPlaylist({
            userId: userId,
            page: startRecord,
        });
    }, [ userId, startRecord]);

    const isPlaylistSelected = (playlistId: string): boolean => {
        return Array.isArray(selectedPlaylist?.data) && selectedPlaylist.data.includes(playlistId);
    };

    const handlePlaylistSelect = (playlistId: string) => {
        const selectedPlaylists = Array.isArray(selectedPlaylist?.data) ? [...selectedPlaylist.data] : [];
        const isCurrentlySelected = selectedPlaylists.includes(playlistId);

        let newSelectedPlaylists: string[];
        if (isCurrentlySelected) {
            newSelectedPlaylists = selectedPlaylists.filter(id => id !== playlistId);
        } else {
            newSelectedPlaylists = [...selectedPlaylists, playlistId];
        }

        const totalLength = userPlaylists?.data
            ?.filter(playlist => newSelectedPlaylists.includes(playlist.id))
            .reduce((sum, playlist) => sum + playlist.totalLength, 0) || 0;

        actionSetSelectedPlaylist({
            data: newSelectedPlaylists,
            playlistLength: totalLength,
            isPlaylist: true,
        });
    };

    return (
        <>
            {userPlaylists?.getPlaylistStatus === requestStatusEnum.PROGRESS ? (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.playlistGrid}>
                    {userPlaylists?.data?.map((playlistData) => (
                        <PlaylistCard
                            key={playlistData.id}
                            playlist={playlistData}
                            selected={isPlaylistSelected(playlistData.id)}
                            onSelect={handlePlaylistSelect}
                        />
                    ))}
                </div>
            )}
        </>
    );
};