import {useEffect, useState} from 'react';
import styles from './Account.module.scss';
import {useLoginSelector} from "../../../store/login/useLoginSelectors";
import {useLoginActions} from "../../../store/login/useLoginActions";
import {useNavigate} from "react-router";
import {requestStatusEnum} from "../../../store/store.types";
import {routes} from "../../../routing/routes";
import Button from "../../../components/Button/Button";
import {ButtonVariantEnum} from "../../../components/Button/button.types";
import {Modal} from "../../../components/Modal";
import {Spinner} from "../../../components/Spinner/Spinner.component";

interface PersonalInfo {
  name: string;
  email: string;
  companyName: string;
  type: string;
  role: string;
  category: string | null;
  subCategory: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  state: string | null;
  country: string | null;
  pincode: string | null;
}

interface PasswordForm {
  currentPassword: string;
  newPassword: string;
  verifyPassword: string;
}

export const AccountComponent: React.FC = () => {
  const {userData, userDataById, AccountDeleteStatus} = useLoginSelector();
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');

  const { actionUpdateUser } = useLoginActions();
  const navigate = useNavigate();

  useEffect(() => {
    AccountDeleteStatus && AccountDeleteStatus === requestStatusEnum.SUCCESS ? navigate(routes().login) : null;
  }, [AccountDeleteStatus]);

  const [passwordForm, setPasswordForm] = useState<PasswordForm>({
    currentPassword: '',
    newPassword: '',
    verifyPassword: ''
  });

  const [showPasswords, setShowPasswords] = useState({
    current: false,
    new: false,
    verify: false,
  });

  const onClose = () => {

    setDeleteModalVisible(false);
  };

  const handlePasswordChange = (field: keyof PasswordForm) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordForm(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  };

  const togglePasswordVisibility = (field: keyof typeof showPasswords) => {
    setShowPasswords(prev => ({
      ...prev,
      [field]: !prev[field]
    }));
  };

  const handleDelete = () => {
    if (deleteInputValue === 'DELETE') {
      console.log('Deleted');
      actionUpdateUser({
        userId: userData?.userId,
        orgId: userData?.ordId,
        name: userData?.name,
        status: 'DELETED',
      });
    }
    setDeleteModalVisible(false);
  };

  return (
      <div className={styles.accountWrapper}>
        <div className={styles.card} style={{position: 'relative'}}>
          <h2 className={styles.cardTitle}>Personal Info</h2>
          <div className={styles.formGrid}>
            <div className={styles.formGroup}>
              <label>Name</label>
              <input
                  type="text"
                  value={userData?.name}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Email</label>
              <input
                  type="email"
                  value={userDataById?.data?.email}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Company Name</label>
              <input
                  type="text"
                  value={userDataById?.data?.organization?.name}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Category</label>
              <input
                  type="text"
                  value={userDataById?.data?.category || ''}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Address Line 1</label>
              <input
                  type="text"
                  value={userDataById.data?.address?.line1 || ''}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Address Line 2</label>
              <input
                  type="text"
                  value={userDataById?.data?.address?.line2 || ''}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>City</label>
              <input
                  type="text"
                  value={userDataById?.data?.address?.city || ''}
                  disabled
                  className={styles.input}
              />
            </div>
            <div className={styles.formGroup}>
              <label>Pincode</label>
              <input
                  type="text"
                  value={userDataById?.data?.address?.zip || ''}
                  disabled
                  className={styles.input}
              />
            </div>
          </div>
          <button
              className={styles.deleteButton}
              onClick={() => setDeleteModalVisible(true)}
          >
            Delete
          </button>
        </div>
        <Modal
            isVisible={isDeleteModalVisible}
            onClose={onClose}
            className={styles.modalClass}
        >
          {AccountDeleteStatus === requestStatusEnum.PROGRESS ? (
              <Spinner />
          ) : (
              <div style={{
                padding: '2rem',
                backgroundColor: '#fff',
                borderRadius: '8px',
              }}>
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  textAlign: 'center'
                }}>
                  <h2 style={{
                    fontSize: '1.5rem',
                    fontWeight: 'bold',
                    marginBottom: '1rem'
                  }}>
                    Delete account
                  </h2>
                  <p style={{
                    fontSize: '1rem',
                    marginBottom: '2rem'
                  }}>
                    {'Deleting your account will remove all of your information from our database. This cannot be undone.'}
                  </p>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '2rem'
                  }}>
                    <input
                        type="text"
                        placeholder="To confirm, type 'DELETE'"
                        value={deleteInputValue}
                        onChange={(e) => setDeleteInputValue(e.target.value)}
                        style={{
                          width: '30rem',
                          padding: '0.5rem 1rem',
                          fontSize: '1rem',
                          border: '1px solid #ccc',
                          borderRadius: '4px',
                          marginRight: '1rem'
                        }}
                    />
                    <div style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '30rem',
                      gap: '2rem',
                      alignItems: 'center',
                      justifyContent: 'center',
                      justifyItems: 'center'
                    }}>
                      <Button
                          text="Delete account"
                          variant={ButtonVariantEnum.error}
                          onClick={handleDelete}
                          disabled={deleteInputValue !== 'DELETE'}
                      />
                    </div>
                  </div>
                </div>
              </div>
          )}
        </Modal>
      </div>
  );
};