import React, { memo } from 'react';
import styles from './channels.module.scss';
import { columnConfigType } from "../../components/Table/Table.types";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import { capitalize } from "../../utils/capitalize";

export type TChannelData = {
    id: string;
    name: string;
    type: string;
    tags: string[];
    location: string;
    timing: string;
    defaultMedia: boolean;
    defaultMediaId: string | null;
    lat: number | null;
    lng: number | null;
    status: string;
    userId?: string;
    orgId?: string;
};

// Instead of using hooks for tooltip, use CSS hover effect
const tooltipStyles = `
  .copyButton {
    position: relative;
  }
  .copyButton::after {
    content: 'Copied!';
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.2s;
    pointer-events: none;
  }
  .copyButton.copied::after {
    opacity: 1;
  }
`;

// Add styles to document
const styleSheet = document.createElement('style');
styleSheet.textContent = tooltipStyles;
document.head.appendChild(styleSheet);

const copyToClipboard = (text: string, element: HTMLElement) => {
    navigator.clipboard.writeText(text);
    element.classList.add('copied');
    setTimeout(() => element.classList.remove('copied'), 2000);
};

// Memoized cell components
const NameCell = memo(({ name }: { name: string }) => (
    <div>
        <Text
            variant={TextVariantsEnum.Text_md}
            fontWeight={FontWeightEnum.Normal}
            color={'neutral-70'}
        >
            {capitalize(name)}
        </Text>
    </div>
));

const StatusCell = memo(({ status }: { status: string }) => (
    <div>
        <Text
            variant={TextVariantsEnum.Text_sm}
            fontWeight={FontWeightEnum.Normal}>
            {status}
        </Text>
    </div>
));

const TagsCell = memo(({ tags }: { tags: string[] }) => (
    <div className={styles.tagsContainer}>
        {Array.isArray(tags) && tags.map((tag, index) => (
            <div
                key={index}
                className={styles.tagItem}
            >
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                    className={styles.tagTextBlueDark}
                >
                    {tag}
                </Text>
            </div>
        ))}
    </div>
));

const LinkCell = memo(({ channelId, userId, orgId }: { channelId: string; userId?: string; orgId?: string }) => {
    const channelUrl = `/channel-player?tenantId=${channelId}&userId=${userId}&orgId=${orgId}`;
    const fullUrl = window.location.origin + channelUrl;

    return (
        <div className={styles.linkContainer}>
            <div className={styles.linkWrapper}>
                <Text
                    variant={TextVariantsEnum.Text_sm}
                    fontWeight={FontWeightEnum.Normal}
                    className={styles.channelLink}
                    onClick={() => window.open(channelUrl, '_blank')}
                >
                    {fullUrl}
                </Text>
                <div className={styles.copyButtonWrapper}>
                    <button
                        className={styles.copyButton}
                        onClick={(e) => {
                            e.stopPropagation();
                            copyToClipboard(fullUrl, e.currentTarget);
                        }}
                    >
                        <img
                            src="./icons/copy.svg"
                            alt="Copy"
                            className={styles.copyIcon}
                        />
                    </button>
                </div>
            </div>
        </div>
    );
});

// Create columns configuration outside of the function to prevent recreation
const createColumns = (userData: { userId?: string; ordId?: string }): columnConfigType<TChannelData>[] => [
    {
        id: 'name',
        name: 'Channel Name',
        cellRenderer: (data) => <NameCell name={data.name} />
    },
    {
        id: 'status',
        name: 'Status',
        cellRenderer: (data) => <StatusCell status={data.status} />
    },
    {
        id: 'tags',
        name: 'Tags',
        cellRenderer: (data) => <TagsCell tags={data.tags} />
    },
    {
        id: 'link',
        name: 'Channel Link',
        cellRenderer: (data) => (
            <LinkCell
                channelId={data.id}
                userId={userData?.userId}
                orgId={userData?.ordId}
            />
        )
    }
];

// Cache for memoized columns
const columnsCache = new Map();

// Export a memoized version of the columns configuration
export const ChannelsTableColumns = (userData: { userId?: string; ordId?: string }): columnConfigType<TChannelData>[] => {
    const cacheKey = `${userData?.userId}-${userData?.ordId}`;

    if (!columnsCache.has(cacheKey)) {
        columnsCache.set(cacheKey, createColumns(userData));
    }

    return columnsCache.get(cacheKey);
};