import React, { useEffect, useState } from 'react';
import { TSelectScreenProps } from "./SelectScreenCluster.types";
import styles from './SelectScreenCluster.module.scss';
import { Table } from "../../../../components/Table/Table.component";
import { getTableColumns } from "./SelectScreenCluster.config";
import { requestStatusEnum } from "../../../../store/store.types";
import { useCampaignsActions } from "../../../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../../../store/campaigns/useCampaignsSelector";
import { Spinner } from "../../../../components/Spinner/Spinner.component";
import { useClustersSelector } from "../../../../store/cluster/useClusterSelector";
import { useClustersActions } from "../../../../store/cluster/useClusterActions";
import { useLoginSelector } from "../../../../store/login/useLoginSelectors";
import { TClusterListData, TscreensListData} from "../../../../store/cluster/cluster.types"

export const SelectScreen: React.FC<TSelectScreenProps> = ({}) => {
    const [tableData, setTableData] = useState<Array<TscreensListData | TClusterListData>>([]);
    const [startRecord, setStartRecord] = useState(0);

    const { actionSetSelectedScreen, actionFetchScreensList, resetCampaigns } = useCampaignsActions();
    const { actionFetchClusters, actionSetSelectedCluster, resetClusters } = useClustersActions();

    const { userScreensList: { data: screensList, status: screensListStatus, totalPages }, SelectedCampaignType, selectedScreens } = useCampaignsSelector();

    const { clusterList: { status: clusterListStatus, data: clusterList, totalPages: clustersTotalPages }, selectedClusters } = useClustersSelector();

    const {
        userData: { ordId: orgId, userId },
    } = useLoginSelector();

    useEffect(() => {
        if (SelectedCampaignType === 'myScreens') {
            actionFetchScreensList({ page: startRecord, userId });
        } else {
            actionFetchClusters({ page: startRecord, orgId });
        }
    }, [SelectedCampaignType, startRecord, userId]);

    useEffect(() => {
        if (SelectedCampaignType === 'myScreens' && screensListStatus === requestStatusEnum.SUCCESS) {
            setTableData(screensList);
        } else if (SelectedCampaignType === 'screenHub' && clusterListStatus === requestStatusEnum.SUCCESS) {
            setTableData(clusterList);
        }
    }, [screensListStatus, screensList, clusterListStatus, clusterList, SelectedCampaignType]);

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        if (SelectedCampaignType === 'myScreens') {
            actionFetchScreensList({ page: startRecord / 20 });
        } else {
            actionFetchClusters({ page: startRecord / 20, orgId });
        }
        setStartRecord(startRecord);
    };

    console.log('screensList => ', screensList)

    const onNameClick = (name: string) => {
        console.log("name", name);
    };

    const onSelectItem = (selectedId: string, selectedName: string) => {
        if (SelectedCampaignType === 'myScreens') {
            if (selectedScreens.includes(selectedId)) {
                actionSetSelectedScreen(selectedScreens.filter(id => id !== selectedId));
            } else {
                actionSetSelectedScreen([...selectedScreens, selectedId]);
            }
        } else if (SelectedCampaignType === 'screenHub') {
            if (selectedClusters.includes(selectedId)) {
                actionSetSelectedCluster(selectedClusters.filter((id) => id !== selectedId));
            } else {
                actionSetSelectedCluster([...selectedClusters, selectedId]);
            }
        }
    };

    const isScreen = SelectedCampaignType === 'myScreens';
    const isLoading = isScreen ? screensListStatus === requestStatusEnum.PROGRESS : clusterListStatus === requestStatusEnum.PROGRESS;
    const totalRecords = isScreen ? totalPages * 20 : clustersTotalPages * 20;
    const isEmpty = !isLoading && (!tableData || tableData.length === 0);

    return (
        <div className={`${styles.tableContainer} ${isEmpty ? styles.isEmpty : ''}`}>
            {isLoading ? (
                <div className={styles.spinnerContainer}>
                    <Spinner />
                </div>
            ) : (
                <div className={styles.screensWrapper}>
                    <Table
                        data={tableData}
                        columns={getTableColumns({ isScreen, onNameClick, onSelectItem, selectedItems: isScreen ? selectedScreens : selectedClusters })}
                        isLoading={false}
                        totalRecords={totalRecords}
                        startRecord={startRecord}
                        recordsPerPage={20}
                        onPaginationClick={onPaginationClick}
                        emptyState={
                            <div className={styles.emptyState}>
                                {isScreen ? 'No Screen Found' : 'No Cluster Found'}
                            </div>
                        }
                        className={`${styles.screenTable} ${isEmpty ? styles.emptyTable : ''}`}
                    />
                </div>
            )}
        </div>
    );
};