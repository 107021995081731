import { api } from "..";

export const CampaignsService = {
    getCampaigns: (payload: {page: number, orgId?: string}) =>
        api.get(`/public/campaign/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data?.response?.campaigns),

    getMerchantScreenCampaigns: (payload: {page: number, orgId?: string}) =>
        api.get(`/public/campaign/merchant/org/${payload.orgId}/page/${payload.page}`).then((data) => data?.data?.response?.campaigns),

    getVerifiedMediaLibrary: (payload: {page: number; orgId: string, status?: string | null}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/${payload.status ?? 'VERIFIED'}/page/${payload.page}`).then((data) => data?.data),

    getUnverifiedMediaLibrary: (payload: {page: number; orgId: string}) =>
        api.get(`/public/media/list/org/${payload.orgId}/status/UN_VERIFIED/page/${payload.page}`).then((data) => data?.data),

    getSelectableScreensList: (payload: {page: number, city?: string, state?: string}) =>
        api.get(`/public/screen/state/${payload.state ?? 'ALL'}/city/${payload.city ?? 'ALL'}/page/${payload.page}`).then((data) => data?.data),

    getScreensList: (payload: {page: number, userId?:string}) =>
        api.get(`/public/screen/user/${payload.userId}/type/TV/page/${payload.page}`).then((data) => data?.data),

    setScreenDefaultmedia: (payload: any) => api.post(`public/campaign/merchant/default`, payload).then((data) => data),

    campaignSaveAsDraft: (payload: any) => api.post('/public/campaign/save', payload).then((data) => data?.data),

    cancelCampaign: ( payload: any ) => api.post('/public/campaign/merchant/cancel', payload).then((data) => data?.data),

    getCampaignInfo: (payload: any) => api.get(`public/campaign/org/${payload.orgId}
    /id/${payload.campaignId}`).then((data) => data?.data?.response),

    scheduleCampaign: (payload: any) =>
        api.post('/public/campaign/schedule', payload).then((data) => data?.data),

    scheduleCampaignMerchantScreen: (payload: any) =>
        api.post('/public/campaign/merchant/schedule/v2', payload).then((data) => data?.data),
}