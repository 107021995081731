import React from 'react';
import {
  FontWeightEnum,
  Text,
  TextVariantsEnum,
} from "../../../components/Text";
import { TUploadMediaProps } from "./UploadMedia.types";
import { FileUploader } from "../../../components/FileUploader/FileUploader.component";
import Button from "../../../components/Button/Button";
import {
  ButtonTextSizeEnum,
  ButtonVariantEnum,
} from "../../../components/Button/button.types";
import { Input } from "../../../components/Input";
import styles from "./UploadMedia.module.scss";

export const UploadMedia: React.FC<TUploadMediaProps> = ({
                                                           onConfirm,
                                                           title,
                                                           description,
                                                           onCancel,
                                                           loading,
                                                           modelStep,
                                                           handleMediaDetails,
                                                           mediaDataUploaded
                                                         }) => {
  const handleDisable = () => {
    if(modelStep === 1) return !mediaDataUploaded.file;
    if(modelStep === 2) return !mediaDataUploaded.mediaName;
    return false;
  };

  return (
      <div className={styles.uploadWrapper}>
        <div className={styles.header}>
          <Text
              variant={TextVariantsEnum.Display_xs}
              fontWeight={FontWeightEnum.Bold}
          >
            {title}
          </Text>
        </div>

        <div className={styles.separator} />

        <div className={styles.contentArea}>
          {modelStep === 1 ? (
              <div className={styles.fileUploaderContainer}>
                <FileUploader
                    file={mediaDataUploaded.file}
                    handleFileUpload={(file) => {
                      handleMediaDetails('file', file?.file);
                      handleMediaDetails('thumbnail', file?.thumbnail);
                      handleMediaDetails('length', file?.duration);
                    }}
                />
              </div>
          ) : (
              <div className={styles.inputClass}>
                <Input
                    type="text"
                    placeholder="Media Name"
                    value={mediaDataUploaded.mediaName}
                    onChange={(e) => {
                      handleMediaDetails('mediaName', e.target.value);
                    }}
                    width="100%"
                />
                <Input
                    type="text"
                    placeholder="Tags (after one tag use , to add another tag)"
                    value={mediaDataUploaded.tags}
                    onChange={(e) => {
                      handleMediaDetails('tags', e.target.value);
                    }}
                    width="100%"
                />
              </div>
          )}
        </div>

        <div className={styles.footer}>
          <Button
              text={modelStep === 1 ? "Cancel" : "Back"}
              onClick={onCancel ?? (() => {})}
              variant={ButtonVariantEnum.secondary}
              size={ButtonTextSizeEnum.sm}
              width="120px"
          />
          <Button
              text={modelStep === 1 ? "Next" : "Upload"}
              onClick={onConfirm}
              variant={ButtonVariantEnum.primary}
              size={ButtonTextSizeEnum.sm}
              width="120px"
              disabled={handleDisable()}
          />
        </div>
      </div>
  );
};

export default UploadMedia;