import { call, takeLatest, all, put } from "redux-saga/effects";
import { AuthService } from "../../services/user/AuthService";
import {TActionGetUserInfoPayload, TActionLoginWithEmailPayload} from "./login.types";
import {
    actionGetUserInfoById, actionGetUserInfoByIdSuccess,
    actionSubmitLogin,
    actionSubmitLoginFailed,
    actionSubmitLoginSuccess, actionUpdateUser, actionUpdateUserFailed, actionUpdateUserSuccess
} from "./login.reducer";
import { auth } from "../../config/firebase";
import {actionAddNotification} from "../notification/notification.reducer";

function* getUserInfoSaga({payload}: TActionGetUserInfoPayload) : any{
    try {
        const data =yield call(AuthService.getUserInfo, payload);
        yield put(actionGetUserInfoByIdSuccess({data}))
    } catch (error) {
        console.log(error)
    }
}

function* updateUserSaga({payload}: any): any {
    try {
        yield call(AuthService.updateUser, payload);
        yield put(actionUpdateUserSuccess());
        yield put(actionAddNotification({
            type: 'success',
            message: 'User Deleted Successfully'
        }));
    } catch (error) {
        yield put(actionUpdateUserFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'User Delete Failed. Please try again in some time'
        }));
    }
}

function* submitEmailLogin({payload}: TActionLoginWithEmailPayload): any{
    try {
        yield call(AuthService.submitEmailLogin, payload)
        const currentUser = auth.currentUser        
        if(currentUser) {
            const userId = currentUser.uid
            const data= yield call(AuthService.loginUpdate, {userId, type: 'CUSTOMER'})
            console.log('data login user => ', data)
            if(data?.accountStatus === 'DELETED') {
                yield put(actionAddNotification({
                    type: 'error',
                    message: "User Doesn't exist"
                }));
            }
            yield put(actionSubmitLoginSuccess({data}));
        }
        else{
            throw Error()
        }
    } catch (error) {
        console.log(error);
        yield put(actionAddNotification({
            type: 'error',
            message: "Login Failed. Please try again with the correct email/username and password"
        }));
        yield put(actionSubmitLoginFailed())
    }
}


export function* loginWatcher() {
    yield all([
        takeLatest(actionSubmitLogin.type, submitEmailLogin),
        takeLatest(actionGetUserInfoById.type, getUserInfoSaga),
        takeLatest(actionUpdateUser.type, updateUserSaga),
    ])
}