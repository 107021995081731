import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";
import { requestStatusEnum } from "../../store/store.types";
import { TScreensListData } from "../../store/screens/screens.types";
import styles from "./screens.module.scss";
import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import Button from "../../components/Button/Button";
import { ButtonTextSizeEnum, ButtonVariantEnum } from "../../components/Button/button.types";
import { Spinner } from "../../components/Spinner/Spinner.component";
import { Table } from "../../components/Table/Table.component";
import { Modal } from "../../components/Modal";
import { OtpPair } from "../../components/OtpPair";
import { SelectMedia } from "../campaign/CreateCampaign/SelectMedia/SelectMedia.component";
import { ScreensTableColumns } from "./screens.config";
import { actionSetScreenDefaultMedia } from "../../store/campaigns/campaigns.reducer";
import { useLoginSelector } from "../../store/login/useLoginSelectors";
import { useMediaActions } from "../../store/media/useMediaActions";
import { useMediaSelector } from "../../store/media/useMediaSelector";
import {useLoginActions} from "../../store/login/useLoginActions";
import {useClustersActions} from "../../store/cluster/useClusterActions";
import {actionAddScreen} from "../../store/cluster/cluster.reducer";
import {useClustersSelector} from "../../store/cluster/useClusterSelector";

export const ScreensComponent = () => {
    const [tableData, setTableData] = useState<Array<TScreensListData>>([]);
    const [startRecord, setStartRecord] = useState(0);
    const [screenName, setScreenName] = useState('');
    const [isAddScreenModalVisible, setIsAddScreenModalVisible] = useState<boolean>(false);
    const [isSelectMediaModalVisible, setIsSelectMediaModalVisible] = useState<boolean>(false);
    const [selectedScreenId, setSelectedScreenId] = useState<string>('');
    const [openedDefaultMediaID, setOpenedDefaultMediaID] = useState<string>('');
    const [isOpenDefaultMediaModalVisible, setIsOpenDefaultMediaModalVisible] = useState<boolean>(false);
    const { media: { data: mediaStream, status: mediaStreamStatus }, mediaDataUploaded } = useMediaSelector();
    const { actionFetchScreensList, actionSetScreenDefaultMedia } = useCampaignsActions();
    const { actionGetMediaStream } = useMediaActions();
    const {
        userScreensList: { data: screensList, status: screensListStatus, totalPages },
        selectedMedia,
        selectedMediaLength,
        setDefaultMediaStatus
    } = useCampaignsSelector();
    const { addScreenStatus } = useClustersSelector();
    const { actionAddScreen } = useClustersActions();
    const { userData, userDataById } = useLoginSelector();
    const { actionGetUserInfoById } = useLoginActions()

    useEffect(() => {
        if (setDefaultMediaStatus === requestStatusEnum.SUCCESS) {
            handleCloseSelectMediaModal();
        }
    }, [setDefaultMediaStatus]);

    useEffect(() => {
        if (setDefaultMediaStatus === requestStatusEnum.SUCCESS || setDefaultMediaStatus === requestStatusEnum.FAILED) {
                actionFetchScreensList({ page: startRecord, userId: userData?.userId });
            if (setDefaultMediaStatus === requestStatusEnum.SUCCESS) {
                handleCloseSelectMediaModal();
            }
        }
    }, [setDefaultMediaStatus]);

    useEffect(() => {
        if (userData?.userId){
            actionGetUserInfoById({
                userId: userData?.userId
            });
            actionFetchScreensList({ page: startRecord, userId: userData?.userId });
        }
    }, [userData?.userId]);

    useEffect(() => {
        if (screensListStatus === requestStatusEnum.SUCCESS && screensList?.length > 0) {
            setTableData(screensList);
        }
    }, [screensListStatus, screensList]);


    const onNameClick = (name: string) => {
        console.log("name", name);
    };

    const handleAddScreenButton = () => {
        setIsAddScreenModalVisible(true);
    };

    const onCloseAddScreenModal = () => {
        setIsAddScreenModalVisible(false);
    };

    const handlePlayDefaultMediaModal = (mediaId: string) => {
        setOpenedDefaultMediaID(mediaId);
        setIsOpenDefaultMediaModalVisible(true);
        actionGetMediaStream({ orgId: userData?.ordId, id: mediaId });
    };

    const handleCloseDefaultMediaModal = () => {
        setIsOpenDefaultMediaModalVisible(false);
        setOpenedDefaultMediaID('');
    };

    const handleOpenSelectMediaModal = (screenId: number) => {
        setSelectedScreenId(screenId.toString());
        setIsSelectMediaModalVisible(true);
    };

    const handleCloseSelectMediaModal = () => {
        setIsSelectMediaModalVisible(false);
        setSelectedScreenId('');
    };

    const handleSaveSelectedMedia = () => {
        const defaultMediaPayload = {
            screenId: selectedScreenId,
            userId: userData?.userId,
            orgId: userData?.ordId,
            mediaId: selectedMedia[0],
            mediaLength: selectedMediaLength.toString(),
        };
        actionSetScreenDefaultMedia(defaultMediaPayload);
        handleCloseSelectMediaModal();
    };

    useEffect(() => {
        if (addScreenStatus === requestStatusEnum.SUCCESS) {
            actionFetchScreensList({ page: startRecord, userId: userData?.userId });
            onCloseAddScreenModal();
            setScreenName('');
        }
    }, [addScreenStatus]);

    const onPaginationClick = (startRecord: number, recordsPerPage: number) => {
        setStartRecord(startRecord);
        actionFetchScreensList({ page: startRecord / recordsPerPage });
    };

    const handleEditScreen = (screenId: number) => {
        // Implement edit functionality
        console.log('Edit screen:', screenId);
    };

    const handleDeleteScreen = (screenId: number) => {
        // Implement delete functionality
        console.log('Delete screen:', screenId);
    };

    const handleChangeDefaultMedia = (screenId: number) => {
        console.log('View screen details:', screenId);
    };

    return (
        <div className={styles.screensWrapper}>
            {setDefaultMediaStatus === requestStatusEnum.PROGRESS && (
                <div className={styles.spinnerOverlay}>
                    <Spinner />
                </div>
            )}
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Screens
                </Text>
                <Button
                    text="Add Screen"
                    variant={ButtonVariantEnum.primary}
                    size={ButtonTextSizeEnum.md}
                    onClick={handleAddScreenButton}
                />
            </div>

            {screensListStatus === requestStatusEnum.PROGRESS ? (
                <Spinner />
            ) : (
                <Table
                    data={tableData}
                    columns={ScreensTableColumns(onNameClick, handleOpenSelectMediaModal, handlePlayDefaultMediaModal, handleOpenSelectMediaModal)}
                    isLoading={false}
                    totalRecords={totalPages * 20}
                    startRecord={startRecord}
                    recordsPerPage={20}
                    onPaginationClick={onPaginationClick}
                    emptyState={"No Screen Found"}
                />
            )}

            <Modal
                isVisible={isAddScreenModalVisible}
                onClose={onCloseAddScreenModal}
                className={styles.modalClass}
                customStyles={{ maxWidth: '500px', maxHeight: '300px' }}
            >
                {addScreenStatus === requestStatusEnum.PROGRESS ? (
                    <div className={styles.spinnerContainer}>
                        <Spinner />
                    </div>
                ) : (
                    <div className={styles.modalContent}>
                        <Text
                            variant={TextVariantsEnum.Display_xs}
                            fontWeight={FontWeightEnum.Bold}
                        >
                            Add Screen Details
                        </Text>

                        <div className={styles.inputWrapper}>
                            <Text
                                variant={TextVariantsEnum.Text_sm}
                            >
                                Screen Name
                            </Text>
                            <input
                                type="text"
                                value={screenName}
                                onChange={(e) => setScreenName(e.target.value.replace(/[<>'"]/g, ''))}
                                className={styles.input}
                                placeholder="Enter screen name"
                                maxLength={50}
                            />
                        </div>

                        <div className={styles.modalActions}>
                            <Button
                                variant={ButtonVariantEnum.secondary}
                                onClick={onCloseAddScreenModal}
                                text="Back"
                            />
                            <Button
                                variant={ButtonVariantEnum.primary}
                                onClick={() => {
                                    if (screenName.trim()) {
                                        actionAddScreen({
                                            name: screenName.trim(),
                                            userId: userData?.userId,
                                            orgId: userData?.ordId,
                                            type: "TV",
                                            status: "ACTIVE",
                                        });
                                    }
                                }}
                                text="Add"
                                disabled={!screenName.trim()}
                            />
                        </div>
                    </div>
                )}
            </Modal>

            <Modal
                isVisible={isSelectMediaModalVisible}
                onClose={handleCloseSelectMediaModal}
                className={styles.selectMediaModalClass}
                customStyles={{ maxWidth: '1200px', maxHeight: '65vh', minHeight: '55vh' }}
            >
                <div className={styles.selectMediaModalContent}>
                    <Text
                        variant={TextVariantsEnum.Display_xs}
                        fontWeight={FontWeightEnum.Bold}
                    >
                        Select Media
                    </Text>
                    <div className={styles.mediaListWrapper}>
                        <SelectMedia/>
                    </div>
                    <div className={styles.modalActions}>
                        <Button
                            variant={ButtonVariantEnum.secondary}
                            onClick={handleCloseSelectMediaModal}
                            text={'Cancel'}
                            disabled={setDefaultMediaStatus === requestStatusEnum.PROGRESS}
                        />
                        <Button
                            variant={ButtonVariantEnum.primary}
                            onClick={handleSaveSelectedMedia}
                            text={'Save'}
                            disabled={setDefaultMediaStatus === requestStatusEnum.PROGRESS}
                        />
                    </div>
                </div>
            </Modal>
            <Modal
                isVisible={isOpenDefaultMediaModalVisible}
                onClose={handleCloseDefaultMediaModal}
                className={styles.videoModalClass}
                customStyles={{
                    maxWidth: '800px',
                    width: '90%',
                    margin: '20px',
                    maxHeight: '500px'
                }}
            >
                <div className={styles.videoModalContent}>
                    <div className={styles.videoWrapper}>
                        {mediaStreamStatus === requestStatusEnum.PROGRESS ? (
                            <div className={styles.spinnerWrapper}>
                                <Spinner />
                            </div>
                        ) : (
                            <video
                                className={styles.video}
                                controls
                                playsInline
                            >
                                <source src={mediaStream} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};