import React, { useEffect, useState, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Maximize2, Minimize2 } from 'lucide-react';
import { Spinner } from '../../../components/Spinner/Spinner.component';
import styles from './channelPlayer.module.scss';

type VideoDetails = {
    url: string;
    orgId: string;
    length: string;
};

type CampaignResponse = {
    response: {
        sequence: string[];
        details: { [key: string]: VideoDetails };
        startTime: string;
        endTime: string;
    };
    errorMessage: string | null;
    errorCode: string | null;
};

const DB_NAME = 'channelPlayerDB';
const STORE_NAME = 'videoStore';

export const ChannelPlayer: React.FC = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [campaignData, setCampaignData] = useState<CampaignResponse | null>(null);
    const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
    const [isBuffering, setIsBuffering] = useState(false);
    const [isFullscreen, setIsFullscreen] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const currentSequenceRef = useRef<string[]>([]);

    const tenantId = searchParams.get('tenantId');
    const userId = searchParams.get('userId');
    const orgId = searchParams.get('orgId');

    const toggleFullscreen = async () => {
        if (!containerRef.current) return;

        try {
            if (!isFullscreen) {
                if (containerRef.current.requestFullscreen) {
                    await containerRef.current.requestFullscreen();
                } else if ((containerRef.current as any).webkitRequestFullscreen) {
                    await (containerRef.current as any).webkitRequestFullscreen();
                } else if ((containerRef.current as any).msRequestFullscreen) {
                    await (containerRef.current as any).msRequestFullscreen();
                }
            } else {
                if (document.exitFullscreen) {
                    await document.exitFullscreen();
                } else if ((document as any).webkitExitFullscreen) {
                    await (document as any).webkitExitFullscreen();
                } else if ((document as any).msExitFullscreen) {
                    await (document as any).msExitFullscreen();
                }
            }
        } catch (error) {
            console.error('Fullscreen error:', error);
        }
    };

    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
        document.addEventListener('mozfullscreenchange', handleFullscreenChange);
        document.addEventListener('MSFullscreenChange', handleFullscreenChange);

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
            document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
        };
    }, []);

    const refreshCampaignData = async () => {
        if (!tenantId || !userId || !orgId) return null;

        try {
            const response = await fetch(`${process.env.REACT_APP_GATEWAY_URL}/open/campaign/download`, {
                method: 'POST',
                headers: {
                    'Clientid': process.env.REACT_APP_ENVIRONMENT === 'dev'
                        ? `${process.env.REACT_APP_UNAUTH_CLIENT_ID_DEV}`
                        : `${process.env.REACT_APP_UNAUTH_CLIENT_ID_PROD}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    screenId: tenantId,
                    userId: userId,
                    orgId: orgId,
                    slot: Math.floor(new Date().setMinutes(0, 0, 0) / 1000).toString()
                })
            });

            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`);
            return await response.json() as CampaignResponse;
        } catch (error) {
            console.error('Error refreshing campaign data:', error);
            return null;
        }
    };

    const playVideo = async (videoId: string, videoDetails: VideoDetails) => {
        if (!videoRef.current) return;

        try {
            // First attempt: Try playing directly from the signed URL
            videoRef.current.src = videoDetails.url;
            await videoRef.current.play();
            console.log(`Playing video ${videoId} from signed URL`);
        } catch (error) {
            console.error(`Failed to play video ${videoId} from signed URL:`, error);

            try {
                const refreshedData = await refreshCampaignData();
                if (refreshedData && refreshedData.response.details[videoId]) {
                    const newUrl = refreshedData.response.details[videoId].url;
                    videoRef.current.src = newUrl;
                    await videoRef.current.play();
                    console.log(`Playing video ${videoId} from refreshed signed URL`);

                    // Update campaign data with new URLs
                    setCampaignData(refreshedData);
                } else {
                    throw new Error('Failed to refresh video URL');
                }
            } catch (secondError) {
                console.error(`Failed to play video ${videoId} after refresh:`, secondError);

                setTimeout(() => {
                    setCurrentVideoIndex(prev =>
                        prev + 1 >= (campaignData?.response.sequence.length || 0) ? 0 : prev + 1
                    );
                }, 1000);
            }
        }
    };

    const playNextVideo = async () => {
        if (!campaignData?.response.sequence || currentVideoIndex >= campaignData.response.sequence.length) {
            setCurrentVideoIndex(0);
            return;
        }

        const videoId = campaignData.response.sequence[currentVideoIndex];
        const videoDetails = campaignData.response.details[videoId];

        if (videoDetails) {
            await playVideo(videoId, videoDetails);
        } else {
            console.error(`No details found for video ${videoId}`);
            setCurrentVideoIndex(prev =>
                prev + 1 >= (campaignData?.response.sequence.length || 0) ? 0 : prev + 1
            );
        }
    };

    useEffect(() => {
        const initializePlayer = async () => {
            try {
                const data = await refreshCampaignData();
                if (data) {
                    setCampaignData(data);
                    currentSequenceRef.current = data.response.sequence;
                } else {
                    throw new Error('Please schedule a campaign to play');
                }
            } catch (error) {
                console.error('Error initializing player:', error);
                setError(error instanceof Error ? error.message : 'Failed to load channel content');
            } finally {
                setIsLoading(false);
            }
        };

        initializePlayer();
    }, []);

    useEffect(() => {
        if (campaignData) {
            playNextVideo();
        }
    }, [campaignData, currentVideoIndex]);

    useEffect(() => {
        const refreshInterval = setInterval(async () => {
            const refreshedData = await refreshCampaignData();
            if (refreshedData) {
                setCampaignData(refreshedData);
            }
        }, 25 * 60 * 1000);

        return () => clearInterval(refreshInterval);
    }, []);

    if (isLoading) {
        return (
            <div className={styles.loadingContainer}>
                <Spinner />
            </div>
        );
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <h2>{error}</h2>
            </div>
        );
    }

    return (
        <div ref={containerRef} className={styles.channelPlayerContainer}>
            <video
                ref={videoRef}
                className={styles.videoPlayer}
                onEnded={() => setCurrentVideoIndex(prev =>
                    prev + 1 >= (campaignData?.response.sequence.length || 0) ? 0 : prev + 1
                )}
                onWaiting={() => setIsBuffering(true)}
                onPlaying={() => setIsBuffering(false)}
                onError={() => {
                    console.error('Video playback error');
                    setCurrentVideoIndex(prev =>
                        prev + 1 >= (campaignData?.response.sequence.length || 0) ? 0 : prev + 1
                    );
                }}
                controls={false}
                autoPlay
                playsInline
                muted
            />
            <button
                className={styles.fullscreenButton}
                onClick={toggleFullscreen}
                aria-label={isFullscreen ? 'Exit fullscreen' : 'Enter fullscreen'}
            >
                {isFullscreen ? (
                    <Minimize2 className={styles.icon} />
                ) : (
                    <Maximize2 className={styles.icon} />
                )}
            </button>
            {isBuffering && (
                <div className={styles.bufferingOverlay} />
            )}
        </div>
    );
};