import { call, takeLatest, all, put } from "redux-saga/effects";
import {TActionCreateClusterPayload, TActionGetClusterListPayload, TActionGetScreensListPayload} from "./cluster.types";
import {
    actionAddScreen,
    actionAddScreenFailed, actionAddScreenSuccess,
    actionCreateChannel,
    actionCreateChannelFailed,
    actionCreateChannelSuccess,
    actionCreateCluster,
    actionCreateClusterFailed,
    actionCreateClusterSuccess,
    actionFetchClusters,
    actionFetchClustersFailed,
    actionFetchClustersSuccess,
    actionFetchScreensList,
    actionFetchScreensListFailed,
    actionFetchScreensListSuccess,
    actionGetChannelsByUser,
    actionGetChannelsByUserFailed,
    actionGetChannelsByUserSuccess
} from "./cluster.reducer";
import { ClustersService } from "../../services/clusters/ClustersService";
import {actionAddNotification} from "../notification/notification.reducer";

function* fetchClusterList({payload}: TActionGetClusterListPayload): any{
    try {
        const data= yield call(ClustersService.getClusters, payload)                       
        yield put(actionFetchClustersSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchClustersFailed())
    }
}

function* fetchScreensList({payload}: TActionGetScreensListPayload): any{
    try {
        const data= yield call(ClustersService.getScreensList, {userId: payload.userId, page: payload.page})
        yield put(actionFetchScreensListSuccess({data: data.response.details,
            totalPages: data.response.totalPages
        }))
    } catch (error) {
        console.log(error);
        yield put(actionFetchScreensListFailed())
    }
}

function* createClusterSaga({payload}: TActionCreateClusterPayload): any{
    try {
        const data= yield call(ClustersService.createCluster, payload)                       
        yield put(actionCreateClusterSuccess(data.response.details))
    } catch (error) {
        console.log(error);
        yield put(actionCreateClusterFailed())
    }
}

function* addScreenSaga({payload}: any) {
    try {
        yield call(ClustersService.addScreen, payload);
        yield put(actionAddScreenSuccess());
        yield put(actionAddNotification({
            type: 'success',
            message: 'Screen Added Successfully'
        }));
    } catch (error) {
        yield put(actionAddScreenFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while adding screen. Please try again in some time'
        }));
    }
}

function* createChannelSaga(payload: any){
    try {
        yield call(ClustersService.createChannel, payload);
        yield put(actionCreateChannelSuccess());
        yield put(actionAddNotification({
            type: 'success',
            message: 'Channel Created Successfully'
        }));
    } catch (error) {
        yield put(actionCreateChannelFailed());
        yield put(actionAddNotification({
            type: 'error',
            message: 'Error occurred while creating channel. Please try again in some time'
        }));
    }
}

function* getChannelByUser(payload: TActionGetScreensListPayload): any {
    try {
        const response = yield call(ClustersService.getChannels, {
            userId: payload?.payload?.userId,
            page: payload?.payload?.page
        });
        yield put(actionGetChannelsByUserSuccess(response?.data?.response?.details));
    }
    catch (error) {
        actionGetChannelsByUserFailed();
    }
}

export function* clustersWatcher() {
    yield all([
        takeLatest(actionFetchClusters.type, fetchClusterList),
        takeLatest(actionFetchScreensList.type, fetchScreensList),
        takeLatest(actionCreateCluster.type, createClusterSaga),
        takeLatest(actionCreateChannel.type, createChannelSaga),
        takeLatest(actionGetChannelsByUser.type, getChannelByUser),
        takeLatest(actionAddScreen.type, addScreenSaga),
    ])
}