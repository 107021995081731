import React, { useEffect, useState } from 'react';
import TimeSlot from './TimeSlot.component';
import styles from "./EventCalenderLarge.module.scss"
import { useCampaignsActions } from "../../store/campaigns/useCampaignsActions";
import { useCampaignsSelector } from "../../store/campaigns/useCampaignsSelector";

// Updated to include all 24 hours
const hours = [
    "00:00 - 01:00", "01:00 - 02:00", "02:00 - 03:00", "03:00 - 04:00",
    "04:00 - 05:00", "05:00 - 06:00", "06:00 - 07:00", "07:00 - 08:00",
    "08:00 - 09:00", "09:00 - 10:00", "10:00 - 11:00", "11:00 - 12:00",
    "12:00 - 13:00", "13:00 - 14:00", "14:00 - 15:00", "15:00 - 16:00",
    "16:00 - 17:00", "17:00 - 18:00", "18:00 - 19:00", "19:00 - 20:00",
    "20:00 - 21:00", "21:00 - 22:00", "22:00 - 23:00", "23:00 - 00:00"
];

// Rest of the utility functions remain the same
const getDaysInMonth = (year: number, month: number): number => {
    return new Date(year, month + 1, 0).getDate();
};

const getFormattedDate = (date: Date): string => {
    const day = date.getDate();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
};

const generateDates = (): string[] => {
    const indiaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
    const currentDate = new Date(indiaTime);
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();

    const dates: string[] = [];
    for (let i = 0; i < 30; i++) {
        const date = new Date(year, month, day + i);
        dates.push(getFormattedDate(date));
    }

    return dates;
};

const EventCalendarLarge: React.FC = () => {
    const { actionAddClusterSlots, actionAddScreensSlots } = useCampaignsActions();
    const [selectedTimestamps, setSelectedTimestamps] = useState<string[]>([]);
    const [isDragging, setIsDragging] = useState(false);
    const [isSelecting, setIsSelecting] = useState(true);
    const [dates, setDates] = useState<string[]>([]);
    const [lastTouchedCell, setLastTouchedCell] = useState<{ row: string; column: string } | null>(null);
    const { SelectedCampaignType } = useCampaignsSelector();
    const [dragStartCell, setDragStartCell] = useState<{ row: string; column: string } | null>(null);
    const [dragEndCell, setDragEndCell] = useState<{ row: string; column: string } | null>(null);

    useEffect(() => {
        setDates(generateDates());
    }, []);

    // Updated to handle 24-hour format
    const isSlotDisabled = (row: string, column: string): boolean => {
        const [day, month] = row.split(' ');
        const [startHour] = column.split(' ')[0].split(':');
        const currentYear = new Date().getFullYear();
        const slotDate = new Date(`${currentYear}-${month} ${day} ${startHour}:00:00`);

        const indiaTime = new Date().toLocaleString("en-US", { timeZone: "Asia/Kolkata" });
        const now = new Date(indiaTime);
        const isCurrentDate = slotDate.getDate() === now.getDate() &&
            slotDate.getMonth() === now.getMonth() &&
            slotDate.getFullYear() === now.getFullYear();

        if (!isCurrentDate) return false;
        const bufferTime = new Date(now);
        bufferTime.setHours(bufferTime.getHours());
        return slotDate < bufferTime;
    };

    // New function to get all cells between drag start and end points
    const getSelectedRange = (start: { row: string; column: string }, end: { row: string; column: string }) => {
        const startRowIndex = dates.indexOf(start.row);
        const endRowIndex = dates.indexOf(end.row);
        const startColIndex = hours.indexOf(start.column);
        const endColIndex = hours.indexOf(end.column);

        const selectedDates = new Set<string>();
        const selectedHours = new Set<string>();

        // Get all rows and columns in the range
        for (let i = Math.min(startRowIndex, endRowIndex); i <= Math.max(startRowIndex, endRowIndex); i++) {
            selectedDates.add(dates[i]);
        }
        for (let i = Math.min(startColIndex, endColIndex); i <= Math.max(startColIndex, endColIndex); i++) {
            selectedHours.add(hours[i]);
        }

        return { selectedDates, selectedHours };
    };

    // Modified selection handlers
    const handleMouseDown = (row: string, column: string) => {
        if (isSlotDisabled(row, column)) return;

        setIsDragging(true);
        setDragStartCell({ row, column });
        setDragEndCell({ row, column });
        setIsSelecting(true);
    };

    const handleMouseEnter = (row: string, column: string) => {
        if (isDragging && !isSlotDisabled(row, column)) {
            setDragEndCell({ row, column });
        }
    };

    const handleMouseUp = () => {
        if (dragStartCell && dragEndCell) {
            const { selectedDates, selectedHours } = getSelectedRange(dragStartCell, dragEndCell);
            
            // Convert selected range to timestamps while preserving existing selections
            const newSelectedTimestamps = new Set(selectedTimestamps); // Start with existing selections
            
            selectedDates.forEach(date => {
                selectedHours.forEach(hour => {
                    const [day, month] = date.split(' ');
                    const [startHour] = hour.split(' ')[0].split(':');
                    const currentYear = new Date().getFullYear();
                    const slotDate = new Date(`${currentYear}-${month} ${day} ${startHour}:00:00`);
                    
                    if (!isSlotDisabled(date, hour)) {
                        const timestamp = (slotDate.getTime() / 1000).toString();
                        newSelectedTimestamps.add(timestamp);
                    }
                });
            });

            setSelectedTimestamps(Array.from(newSelectedTimestamps));
        }

        setIsDragging(false);
        setDragStartCell(null);
        setDragEndCell(null);
    };

    // Modified cell selection check
    const isCellSelected = (row: string, column: string) => {
        // First check if it's in existing selections
        const [day, month] = row.split(' ');
        const [startHour] = column.split(' ')[0].split(':');
        const currentYear = new Date().getFullYear();
        const date = new Date(`${currentYear}-${month} ${day} ${startHour}:00:00`);
        const timestamp = (date.getTime() / 1000).toString();
        const isInExistingSelection = selectedTimestamps.includes(timestamp);

        // Then check if it's in current drag selection
        if (isDragging && dragStartCell && dragEndCell) {
            const { selectedDates, selectedHours } = getSelectedRange(dragStartCell, dragEndCell);
            const isInDragSelection = selectedDates.has(row) && selectedHours.has(column);
            return isInExistingSelection || isInDragSelection; // Return true if in either selection
        }

        return isInExistingSelection;
    };

    // Modified touch handlers
    const handleTouchStart = (row: string, column: string, e: React.TouchEvent) => {
        if (isSlotDisabled(row, column)) return;
        
        e.preventDefault(); // Prevent scrolling while selecting
        setIsDragging(true);
        setDragStartCell({ row, column });
        setDragEndCell({ row, column });
        setLastTouchedCell({ row, column });
    };

    const handleTouchMove = (e: React.TouchEvent) => {
        if (!isDragging) return;
        
        e.preventDefault(); // Prevent scrolling while selecting
        
        const touch = e.touches[0];
        // Get the element under the touch point
        const element = document.elementFromPoint(touch.clientX, touch.clientY);
        if (!element) return;

        // Find the closest TimeSlot element
        const timeSlotElement = element.closest('[data-row][data-column]') as HTMLElement;
        if (!timeSlotElement) return;

        const row = timeSlotElement.getAttribute('data-row');
        const column = timeSlotElement.getAttribute('data-column');

        if (row && column && !isSlotDisabled(row, column)) {
            // Only update if we've moved to a new cell
            if (!lastTouchedCell || lastTouchedCell.row !== row || lastTouchedCell.column !== column) {
                setDragEndCell({ row, column });
                setLastTouchedCell({ row, column });
            }
        }
    };

    const handleTouchEnd = (e: React.TouchEvent) => {
        e.preventDefault(); // Prevent any default touch end behavior
        
        if (dragStartCell && dragEndCell) {
            const { selectedDates, selectedHours } = getSelectedRange(dragStartCell, dragEndCell);
            
            // Convert selected range to timestamps while preserving existing selections
            const newSelectedTimestamps = new Set(selectedTimestamps);
            
            selectedDates.forEach(date => {
                selectedHours.forEach(hour => {
                    const [day, month] = date.split(' ');
                    const [startHour] = hour.split(' ')[0].split(':');
                    const currentYear = new Date().getFullYear();
                    const slotDate = new Date(`${currentYear}-${month} ${day} ${startHour}:00:00`);
                    
                    if (!isSlotDisabled(date, hour)) {
                        const timestamp = (slotDate.getTime() / 1000).toString();
                        newSelectedTimestamps.add(timestamp);
                    }
                });
            });

            setSelectedTimestamps(Array.from(newSelectedTimestamps));
        }

        // Reset all touch-related state
        setIsDragging(false);
        setDragStartCell(null);
        setDragEndCell(null);
        setLastTouchedCell(null);
    };

    useEffect(() => {
        SelectedCampaignType === 'myScreens'
            ? actionAddScreensSlots(selectedTimestamps)
            : actionAddClusterSlots(selectedTimestamps);
    }, [selectedTimestamps]);

    return (
        <div 
            className={styles.board}
            onTouchMove={(e) => e.preventDefault()} // Prevent page scrolling during selection
        >
            <div className={styles.times}>
                <div className={`${styles.timeSlot} ${styles.firstTimeSlot}`}>
                    <span className={styles.timeLabel}></span>
                </div>
                {hours.map((hour, index) => (
                    <div key={index} className={styles.timeSlot}>
                        <span className={styles.timeLabel}>{hour}</span>
                    </div>
                ))}
            </div>
            <div className={styles.grid}>
                {dates.map((date, dateIndex) => (
                    <div key={dateIndex} className={styles.column}>
                        <div className={styles.dateCell}>
                            <span className={styles.dateLabel}>{date}</span>
                        </div>
                        {hours.map((hour, hourIndex) => (
                            <div key={hourIndex} className={styles.cell}>
                                <TimeSlot
                                    row={date}
                                    column={hour}
                                    isSelected={isCellSelected(date, hour)}
                                    isDisabled={isSlotDisabled(date, hour)}
                                    onMouseDown={() => handleMouseDown(date, hour)}
                                    onMouseEnter={() => handleMouseEnter(date, hour)}
                                    onMouseUp={handleMouseUp}
                                    onTouchStart={(e) => handleTouchStart(date, hour, e)}
                                    onTouchMove={handleTouchMove}
                                    onTouchEnd={(e) => handleTouchEnd(e)}
                                    data-row={date}
                                    data-column={hour}
                                />
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default EventCalendarLarge;