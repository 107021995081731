import { FontWeightEnum, Text, TextVariantsEnum } from "../../components/Text";
import styles from './Settings.module.scss'
import {TabSwitch} from "../../components/Tabswitch/TabSwitch.component";
import {useEffect, useState} from "react";
import {Account} from "./Account";
import {Billing} from "./Billing";
import {useLoginActions} from "../../store/login/useLoginActions";
import {useLoginSelector} from "../../store/login/useLoginSelectors";
export const SettingsComponent = () => {

    const [activeTab, setActiveTab] = useState('account');
    const { userData}  = useLoginSelector();

    const tabs = [
        { label: 'Account', value: 'account' },
        { label: 'Billing', value: 'billing' }
    ];


    return (
        <div className={styles.settingsWrapper}>
            <div className={styles.headerWrapper}>
                <Text
                    variant={TextVariantsEnum.Display_sm}
                    fontWeight={FontWeightEnum.Bold}
                    className={styles.header}
                >
                    Accounts
                </Text>
            </div>
            <div className={styles.contentWrapper}>
            {/*<TabSwitch
                tabs={tabs}
                activeTab={activeTab}
                onTabChange={setActiveTab}
                className={styles.tabSwitchWrapper}
            />*/}
            {activeTab==='account' ? (
                <Account />
            ) : (
                <Billing />
            )}
            </div>
            </div>
    )
}